import React, { useState } from 'react';
import { Row, Col, button } from 'antd';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import flecha_derecha from '../../../assets/images/sitio/1_HOME/iconoflecha_derecha.png';
import flecha_izquierda from '../../../assets/images/sitio/1_HOME/iconoflecha_izq.png';


import revista_mar_2008 from '../../../assets/images/sitio/1_HOME/revista-marzo2008.png';
import reporte_2010 from '../../../assets/images/sitio/1_HOME/reporte2010.png';
import revista_jun_2008 from '../../../assets/images/sitio/1_HOME/revista-junio2008.png';
import reporte_2012 from '../../../assets/images/sitio/1_HOME/reporte2012.png';
import revista_sep_2008 from '../../../assets/images/sitio/1_HOME/revista-septiembre2008.png';
import reporte_2014 from '../../../assets/images/sitio/1_HOME/reporte2014.png';
import revista_dic_2008 from '../../../assets/images/sitio/1_HOME/revista-42.png';
import reporte_2015 from '../../../assets/images/sitio/1_HOME/reporte2015-16.png';
import reporte_2022 from '../../../assets/images/sitio/1_HOME/Vistahome_compendio_.png';

const BannerPublicaciones = () => {

  const ref_carousel_publicaciones = React.createRef();
  const ref_carousel_publicaciones_med = React.createRef();
  const ref_carousel_publicaciones_peq = React.createRef();
  const [activar_publicaciones, setActivarPublicaciones] = useState(0);
  const [activar_publicaciones_med, setActivarPublicaciones_med] = useState(0);
  const [activar_publicaciones_peq, setActivarPublicaciones_peq] = useState(0);

  const publica = [
    {img:revista_mar_2008,url:'/publicaciones?00#revista'},
    {img:reporte_2010,url:'/publicaciones?00#reportes'},
    {img:revista_jun_2008,url:'/publicaciones?01#revista'},
    {img:reporte_2012,url:'/publicaciones?01#reportes'},
    {img:revista_sep_2008,url:'/publicaciones?02#revista'},
    {img:reporte_2014,url:'/publicaciones?02#reportes'},
    {img:revista_dic_2008,url:'/publicaciones?03#revista'},
    {img:reporte_2015,url:'/publicaciones?03#reportes'},
    {img:reporte_2022,url:'/publicaciones?04#reportes'},
  ]

  const settings = {
    dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      arrows:false,
      
};
const settings_medio = {
  dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows:false,
    
};
const settings_chico = {
  dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows:false,
    
};
  
  const cambiarCarousel = (adelante) => {
  if(adelante){
      if(activar_publicaciones === 2){
          setActivarPublicaciones(0);
          setActivarPublicaciones_med(0);
          setActivarPublicaciones_peq(0);
      }else{
          setActivarPublicaciones(activar_publicaciones+1);
          setActivarPublicaciones_med(activar_publicaciones+1);
          setActivarPublicaciones_peq(activar_publicaciones+1);
      }
      ref_carousel_publicaciones.current.slickNext();
      ref_carousel_publicaciones_med.current.slickNext();
      ref_carousel_publicaciones_peq.current.slickNext();
  }else{
      if(activar_publicaciones === 0){
          setActivarPublicaciones(2);
          setActivarPublicaciones_med(2);
          setActivarPublicaciones_peq(2);
      }else{
          setActivarPublicaciones(activar_publicaciones-1);
          setActivarPublicaciones_med(activar_publicaciones-1);
          setActivarPublicaciones_peq(activar_publicaciones-1);
      }
      ref_carousel_publicaciones.current.slickPrev();
      ref_carousel_publicaciones_med.current.slickPrev();
      ref_carousel_publicaciones_peq.current.slickPrev();
  }
  
}
let ver_publicaciones = publica.map((ver)=>{
  return(
    <div>
    <Row justify="space-around" align="middle">
      <Col span={24} className="container_publicaciones">
        <Link to={ver.url}><img src={ver.img} className="img_carousel_publicaciones" alt="vision" />
        <div class="overlay">
          <div class="text"></div>
          </div></Link>
      </Col>
    </Row>
</div>
  );
});

  return (
    
    <>
    <Row>
      <Col md={{span:24, offset:0}} className="contenedor_banner_publicaciones">
        <Row>
          <Col xs={{span:20, offset:2}} md={{span:12, offset:6}}>
            <p className="titulo_publcaciones_home color_negro">PUBLICACIONES</p>
          </Col>
          <Col md={{span:24, offset:0}}>
            <Row align="middle">
              <Col xs={0} md={0} lg={24} offset={0}>
                <div>
                  <Slider ref={ref_carousel_publicaciones} {...settings} style={{marginTop:'0px', paddingtop:'0px'}}>
                    {ver_publicaciones}
                  </Slider>
                </div>
              </Col>
              <Col xs={0} md={{span:20, offset:2}} lg={0} offset={0}>
                <div>
                  <Slider ref={ref_carousel_publicaciones_med} {...settings_medio} style={{marginTop:'0px', paddingtop:'0px'}}>
                    {ver_publicaciones}
                  </Slider>
                </div>
              </Col>
              <Col xs={{span:22, offset:1}} md={0} lg={0} offset={0}>
                <div>
                  <Slider ref={ref_carousel_publicaciones_peq} {...settings_chico} style={{marginTop:'0px', paddingtop:'0px'}}>
                    {ver_publicaciones}
                  </Slider>
                </div>
              </Col>
              <Col span={4} offset={8} style={{textAlign:'right'}}>
                <img src={flecha_izquierda} className="img_carousel_flecha" alt="carousel_izquierda" onClick={() => cambiarCarousel(false)} />
              </Col>
              <Col span={4} offset={1} style={{textAlign:'left'}}>
                <img src={flecha_derecha} className="img_carousel_flecha" alt="carousel_derecha" onClick={() => cambiarCarousel(true)} />
              </Col>
              
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </>
  );
}

export default BannerPublicaciones;