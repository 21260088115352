import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Row, Col, Anchor, Modal, Form, notification, Input } from 'antd';
import Errores from '../Errores';
import { guardar_mail } from '../../../utils/ConexionServidor';
import axios from "axios";
import cap1 from '../../../assets/images/sitio/publicaciones/compendio/CANIMOLT_PortadaCompendio.jpg';
import cap2 from '../../../assets/images/sitio/publicaciones/compendio/CANIMOLT_PortadaCompendio_cap2.jpg';
import cap3 from '../../../assets/images/sitio/publicaciones/compendio/portada_Cap3_CANIMOLT.jpg';
import cap4 from '../../../assets/images/sitio/publicaciones/compendio/portada_Cap4_CANIMOLT.jpg';
import cap5 from '../../../assets/images/sitio/publicaciones/compendio/portada_cap5.jpg';
import cap6 from '../../../assets/images/sitio/publicaciones/compendio/portada_cap6.jpg';
import cap7 from '../../../assets/images/sitio/publicaciones/compendio/portada_cap7.jpg';
import cap8 from '../../../assets/images/sitio/publicaciones/compendio/portada_8.jpg';


import proximo from '../../../assets/images/sitio/publicaciones/compendio/banner_cap6_prox.jpg';

import { useHistory } from "react-router-dom";

import '../../../assets/css/publicaciones.css';

let ublicaciones = [
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_2022-2021-cap1&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'1', item:'status221',img:cap1, titulo:'Capítulo 1: Panorama global del trigo', tipo:'Compendio 2022 - 2023 Capitulo 1'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap2&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'2', item:'status222',img:cap2, titulo:'Capítulo 2: Mercado del trigo en México', tipo:'Compendio 2022 - 2023 Capitulo 2'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap3&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'3', item:'status223',img:cap3, titulo:'Capítulo 3: Derivados del Trigo (Molienda)', tipo:'Compendio 2022 - 2023 Capitulo 3'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap4&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'4', item:'status224',img:cap4, titulo:'Capítulo 4: Industria panificadora', tipo:'Compendio 2022 - 2023 Capitulo 4'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap5&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'5', item:'status225',img:cap5, titulo:'Capítulo 5: Industria galletera', tipo:'Compendio 2022 - 2023 Capitulo 5'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap6&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'6', item:'status226',img:cap6, titulo:'Capítulo 6: Industria de pastas para sopa', tipo:'Compendio 2022 - 2023 Capitulo 6'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap7&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'7', item:'status227',img:cap7, titulo:'Capítulo 7: Acuerdos de libre comercio y normas oficiales', tipo:'Compendio 2022 - 2023 Capitulo 7'},
    {link:'https://e.issuu.com/embed.html?d=canimolt_compendio_cap8&hideIssuuLogo=true&hideShareButton=true&u=canimolt',numero:'8', item:'status228',img:cap8, titulo:'Capítulo 8: Indicadores y variables económicas', tipo:'Compendio 2022 - 2023 Capitulo 8'},
    //{link:'',numero:'', item:''}
]

const Compendio2022 = () => {
    //console.log('local', localStorage.status);

    const [modal_mail, setModalMail] = useState(false);
    const [modal_compendio, setModalCompendio] = useState(false);
    const [numero,setnumero] = useState(0);
    const [tipo, setTipo] = useState('')
    const [error, seterror] = useState('');
    const history = useHistory();
    const location = useLocation();

    const mensaje = (tipo, titulo) => {
        notification[tipo]({
            message: titulo,
            duration: 10,
        });
    }
    useEffect(() => {
        console.log(location.hash.slice(1));
        if(location.hash != ''){
            setnumero(location.hash.slice(1));
            setModalMail(true)
        }
    },[location]);

    console.log(numero);
    const ver_img = ublicaciones.map((cuadros) => {
        return(
            <>
            <Col span={6} >
                <img style={{width:'90%', cursor:'pointer'}} src={cuadros.img} onClick={()=>{setModalMail(true);setnumero(cuadros.item);setTipo(cuadros.tipo)}} alt='' />
                <p className='titulo_cap'>{cuadros.titulo}</p>
            </Col>
            </>
        )
    });
    const ver_compendio = ublicaciones.filter((doc) => {
        if(numero === doc.item){
            return true;
        }else{
            return false;
        }
    }).map((documentos) =>{
        return(
            <>
            <Col span={20} offset={2}><br />
            <p className='titulo_cap'>{documentos.titulo}</p>
                <div style={{ position: 'relative', paddingTop: 'max(60%,326px)', height: '0', width: '100%' }}><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style={{ position: 'absolute', border: 'none', width: '100%', height: '100%', left: '0', right: '0', top: '0', bottom: '0' }} src={documentos.link}></iframe></div>
                <br /><br />
            </Col>
            </>
        )
    });
    const onFinish = async values => {
        values.type = tipo;
        guardar_mail(values).then(({ data, status, success }) => {
            switch (success) {
                case true:
                    localStorage.setItem('email', data.email);
                    localStorage.setItem(numero, success);

                    setModalMail(false);
                    setModalCompendio(true);
                    break;
                default:
                    mensaje('error', 'Advertencia');
                    break;
            }
        });

        console.log('selecccion', numero)
    };

    /*useEffect(() => {
        if (localStorage.status221 != 'true') {
            setModalMail(true);
        } else {
            setModalMail(false);
        }
    });*/

    useEffect(() => {
        const cleanup = () => {
          // do your cleanup
          localStorage.removeItem('status');
          localStorage.clear();
        }
        window.addEventListener('beforeunload', cleanup);
        return () => {
            window.removeEventListener('beforeunload', cleanup);
        }
        }, []);
    
    return (
        <Row>
            <Col span={24} id="revista">

                <p className="titulo_revista_reportes">
                    Compendio Estadístico 2022 - 2023

                </p>
            </Col>
            <Col span={20} offset={2}><br />
                <Row justify={'start'}>
                    {ver_img}
                </Row>
                <br /><br />
            </Col>
            <Col span={20} offset={2} style={{display:'none'}}><br />
                <img src={proximo} style={{width:'100%'}} />


                <br /><br />
            </Col>
            
            <Modal
                visible={modal_compendio}
                onOk={() => {setModalCompendio(false);setnumero(0)}}
                onCancel={() => {setModalCompendio(false);setnumero(0)}}
                closable={true}
                title={null}
                footer={null}
                style={{width:'100%'}}
                width={1400}
                bodyStyle={{}}>
                {ver_compendio}
            </Modal>


            <Modal
                visible={modal_mail}
                onOk={() => setModalMail(false)}
                onCancel={() => setModalMail(false)}
                closable={false}
                title="Para visualizar el documento ingresa un correo"
                footer={null}
                style={{}}
                bodyStyle={{}}>
                <Form className="login-form" onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="email"
                        label={<span style={{ color: '#000000' }}>Correo electrónico</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa tu correo!',
                                type: 'email'
                            },
                        ]}
                    >
                        <Input style={{ backgroundColor: '#F5F8F5' }} />
                    </Form.Item>
                    {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                    <Form.Item>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>

                                <button htmlType="submit" className="btn-amarillo boton-amarillo">
                                    Entrar
                                </button>

                            </Col>
                        </Row>

                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}

export default Compendio2022;
