import React from 'react';
import { Row, Col } from 'antd';

//import wsp_am from '../../../assets/images/sitio/1_HOME/btn_whatsam.png';
import wsp_bl from '../../../assets/images/sitio/HEADER/industriales_unidos.jpeg';

const FloatLogo = () => {

  //const [mostrar_wsp_bl, mostrarWspBlanco] = useState(false);

  return (
    <Row style={{ }} className="content_logo_fixed">
      <Col xs={{span:2, offset:21}} lg={{span:2, offset:21}}>
        <img alt="" src={wsp_bl} className="sticky_logo" />
      </Col>
    </Row>
  );
}
export default FloatLogo;