import React from 'react';
import CarouselConocenos from './CarouselConocenos';
import TablaConsejo from './TablaConsejo';

import '../../../assets/css/conocenos.css';
import CarouselSocios from './CarouselSocios';

const Conocenos = () => {

    return(
        <>
            <CarouselConocenos />
            <CarouselSocios />
            <TablaConsejo />
        </>
    );
}

export default Conocenos;
