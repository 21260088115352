import React from 'react';
import { Row, Col } from 'antd';

const App = () => {
  return(
    <>
    <Row>
      <Col span={24}>
        <br /><br />
      </Col>
      <Col span={24}>
      <iframe title="contacto" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.5396238789494!2d-99.17589628509396!3d19.389076986909032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff76a4a85e2b%3A0x2ef0909cab04aaaf!2sAv.%20Insurgentes%20Sur%20826%2C%20Col%20del%20Valle%20Centro%2C%20Benito%20Ju%C3%A1rez%2C%2003100%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1600457387794!5m2!1ses-419!2smx" style={{width:'100%', height:'500px', border:'0'}} frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </Col>
    </Row>
    </>
  );
};

export default App;
