import React from 'react';
import { Row, Col } from 'antd';
import TablasTipoGranoPais from './TablasTipoGranoPais';
import { Element } from 'react-scroll';

import icono_trigo from '../../../assets/images/sitio/4_trigo/icono_secciontrigo.png';
import icono_espiga_trigo from '../../../assets/images/sitio/4_trigo/imgn_trigo_espiga.png';
import morfologia_planta from '../../../assets/images/sitio/4_trigo/morfologia_planta.gif';
import estructura_grano from '../../../assets/images/sitio/4_trigo/imgn_partesdelgrano.jpg';
import trigo_mexico from '../../../assets/images/sitio/4_trigo/imgn_mexicoycereales.png';

import '../../../assets/css/trigo.css';

const Trigo = () => {

    return (
        <>
            <Row className="banner_principal_trigo">
                <Col xs={{ span: 16, offset: 4 }} md={{ span: 8, offset: 8 }} span={24} >
                    <p className="contenedor_icono_principal_trigo">
                        <img alt="" src={icono_trigo} className="img_icono_principal_trigo" />
                        <br />
                        <span class="palabras_principal_trigo">Trigo</span>
                    </p>
                </Col>
            </Row>
            <br />
            <Row className="back_amarillo" >
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                    <Element name="#definicion"></Element>
                    <p className="texto_titulo_trigo">
                        DEFINICIÓN
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                    <p className="texto_info_trigo color_gris">
                        Es una planta gramínea de la familia del césped, su crecimiento promedio es de un metro de altura. Sus
                        hojas brotan muy pronto y van seguidas por tallos muy delgados rematados por espigas de cuyos granos
                        molidos se saca la harina.
                        <br /><br />
                        Las condiciones del clima y suelo que necesita el trigo para su cultivo, no son especificas, pues se adapta
                        a circunstancias diversas, pero principalmente se realiza en zonas templadas. La condición óptima
                        depende de la etapa del desarrollo, de la variedad y del tipo de plantas. Sin embargo, para obtener una
                        buena cosecha, es necesario que la condición física del suelo tenga las siguientes características:
                        <br /><br />

                        <img alt="" src={icono_espiga_trigo} className="img_espiga_trigo" />

                        <span className="border-left-amarillo"> Una estructura granular, que permita la
                            aireación y el movimiento del agua.
                        </span>
                        <br /><br />
                        <span className="border-left-amarillo"> Un perl de tierra cultivable de hasta
                            unos 30cm, para un enraizamiento adecuado.
                        </span>
                        <br /><br />
                        <span className="border-left-amarillo"> Que no sea susceptible a la formación
                            de costras que dificulten la germinación.
                        </span>
                        <br /><br />

                        <br /><br />



                        <span className="texto_titulo_cereales">
                            CARACTERÍSTICAS DEL CULTIVO
                        </span>
                        <br /><br />
                        Antes de sembrar el trigo, es necesario analizar el suelo de acuerdo a su fertilidad para determinar la
                        variedad del grano a cultivar, esto asegurará mayores rendimientos y una mejor calidad. La salinidad en
                        exceso, impide y en algunos casos, disminuye la germinación del grano, incluso puede llegar a afectar su
                        productividad.
                        <br /><br />
                        Es importante considerar que para lograr siembras de trigo altamente rentables o productivas, es
                        necesario la rotación de cultivos en la misma tierra, alternando el trigo con otros cultivos de cereales, esto
                        contribuirá a mejorar la fertilidad de la tierra, cumpliendo así con un perl cultivable que no conforme
                        costras, que permita la aireación y el aprovechamiento del agua, además de que regulará la acidez o
                        alcalinidad del suelo. El monocultivo (es decir, la siembra de una sola gramínea), no sólo disminuye la
                        calidad de las tierras, sino también permite la proliferación de enfermedades de las plantas y la aparición
                        de plagas.
                        <br /><br />
                        Las plagas y enfermedades más comunes en el trigo son el hongo fusarium, la tilletia indica, las chinches,
                        la nefasia, y la roya, entre otras.

                    </p>
                </Col>
            </Row>
            <Row className="back_amarillo">
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                    <Element name="#morfologia"></Element>
                    <p className="texto_titulo_trigo">
                        MORFOLOGÍA DE LA PLANTA
                    </p>
                </Col>
            </Row>

            <Row align="middle">
                <Col xs={{ span: 16, offset: 4 }} md={{ span: 6, offset: 2 }}>
                    <img alt="" src={morfologia_planta} className="morfoligia_planta" />
                </Col>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 13, offset: 1 }}>
                    <p className="texto_morfologia_planta color_gris">
                        <strong>1.</strong> La altura que varía entre los 30 y 180 cm. <br />
                        <strong>2.</strong> El tallo es recto y cilíndrico. Tiene nudos. <br />
                        <strong>3.</strong> El nudo es sólido. La mayoría de los trigos tienen seis nudos. <br />
                        <strong>4.</strong> La hoja es lanceolada, con un ancho de 0,5 a 1cm y una longitud
                        de 15 a 25cm. Cada planta tiene de cuatro a seis hojas. <br />
                        <strong>5.</strong> La lígula es de longitud media. <br />
                        <strong>6.</strong> La aurícula es despuntada y tiene pelos. La lígula y la aurícula se
                        sirven en la identificación de las plántulas.<br />
                        <strong>7.</strong> En la plántula las hojas se despliegan al nacer, girando en el
                        sentido de las manecillas del reloj. Esta es también una característica
                        en la identificación de las plántulas. <br />
                        <strong>8.</strong> Amacollamiento. Esta es otra característica en los cereales. Las
                        plántulas producen macollos de número variable, generalmente de
                        dos a siete.<br />
                        <strong>9.</strong> Las raíces del trigo son semejantes a la de la cebada y la de la
                        avena. <br />
                        <strong>10.</strong> Las raíces permanentes o secundarias nacen en le primer nudo. <br />
                        <strong>11.</strong> Raíces que nacen a partir de la semilla. Normalmente existen
                        cinco raíces seminales, una radical o primaria y cuatro laterales, que
                        funcionan durante toda la vida de la planta.<br />
                        <strong>12.</strong> La espiga del trigo macarrón es densa y corta. Consiste en una
                        innidad de espiguillas que terminan en una arista o barba. <br />
                        <strong>13.</strong> Los granos del trigo macarrón son generalmente alargados,
                        puntiagudos, durísimos y de color ámbar rojizo. <br />
                        <strong>14.</strong> Espiga del trigo común.<br />
                        <strong>15.</strong> Los granos del trigo común pueden ser blandos o duros.
                    </p>
                </Col>
            </Row>

            <Row className="back_amarillo">
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 2 }}>
                    <Element name="#estructura"></Element>
                    <p className="texto_titulo_trigo">
                        ESTRUCTURA DEL GRANO
                    </p>
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }} style={{ textAlign: 'center' }}>
                    <br />
                    <img alt="" src={estructura_grano} className="img_estructura_grano" />
                    <br /><br />
                </Col>

            </Row>

            <Row className="back_amarillo">
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 2 }}>
                    <Element name="#tipos"></Element>
                    <p className="texto_titulo_trigo">
                        TIPOS DE GRANO
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                    <TablasTipoGranoPais />
                </Col>
            </Row>
            
            <br /><br />
        </>
    );
}

export default Trigo;
