import React, {useEffect} from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import {Element, scroller} from 'react-scroll';
import Header from './Header';
import Footer from './Footer';
import FloatWsp from './FloatWsp';
import FloatLogo from './FloatLogo';
import RouterNotas from '../blog/RouterNotas';


import Home from '../home/Home';
import Conocenos from '../conocenos/Conocenos';
import Cereales from '../cereales/Cereales';
import Trigo from '../trigo/Trigo';
import ProductosSocios from '../productos_socios/ProductosSocios';
import Harina from '../harina/Harina';
import CadenasProductivas from '../cadenas/CadenasProductivas';
import Blog from '../blog/Blog';
import ComercioMarco from '../comercio/ComercioMarco';
import Contacto from '../contacto/Contacto';


import '../../../assets/css/general.css';
import Publicaciones from '../publicaciones/Publicaciones';
import Compendio from '../../paginas/Compendio';
import CompendioCap2 from '../../paginas/CompendioCap2';
import CompendioCap3 from '../../paginas/CompendioCap3';
import CompendioCap4 from '../../paginas/CompendioCap4';
import CompendioCap5 from '../../paginas/CompendioCap5';
import CompendioCap6 from '../../paginas/CompendioCap6';
import CompendioCap7 from '../../paginas/CompendioCap7';
import CompendioCap8 from '../../paginas/CompendioCap8';
import CompendioCompleto from '../../paginas/CompendioCompleto';
import Capitulo221 from '../../paginas/compendio2022/Capitulo221';
import Capitulo222 from '../../paginas/compendio2022/Capitulo222';
import Compendio2022 from '../../paginas/compendio2022/Compendio2022';

const Master = () => {
  const location = useLocation();

    

    useEffect(() => {
      if(location.hash === '#trimex'){
        window.scrollTo(0, 0)
      }
        if(location.hash === ''){
            scroller.scrollTo('header');
        }else{
            console.log('ir');
            //console.log(location.hash);
            scroller.scrollTo(location.hash);
        }
        
    },[location]);
  return(
    <>
    <Element name="header"></Element>
      <Header />
      <FloatWsp />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/conocenos" component={Conocenos} />
        <Route exact path="/cereales" component={Cereales} />
        <Route exact path="/trigo" component={Trigo} />
        <Route exact path="/publicaciones" component={Publicaciones} />
        <Route exatt path="/productos-socios/:harinera?" component={ProductosSocios} />
        <Route exact path="/harina" component={Harina} />
        <Route exact path="/cadenas-productivas" component={CadenasProductivas} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/comercio-exterior-y-marco-normativo" component={ComercioMarco} />
        <Route exact path="/compendio-estadistico" component={Compendio} />
        <Route exact path="/compendio-estadistico-capitulo-2" component={CompendioCap2} />
        <Route exact path="/compendio-estadistico-capitulo-3" component={CompendioCap3} />
        <Route exact path="/compendio-estadistico-capitulo-4" component={CompendioCap4} />
        <Route exact path="/compendio-estadistico-capitulo-5" component={CompendioCap5} />
        <Route exact path="/compendio-estadistico-capitulo-6" component={CompendioCap6} />
        <Route exact path="/compendio-estadistico-capitulo-7" component={CompendioCap7} />
        <Route exact path="/compendio-estadistico-capitulo-8" component={CompendioCap8} />
        <Route exact path="/compendio-estadistico-completo" component={CompendioCompleto} />
        <Route exact path="/compendio-estadistico-2022-2023-capitulo-1" component={Capitulo221} />
        <Route exact path="/compendio-estadistico-2022-2023" component={Compendio2022} />
        <Route exact path="/compendio-estadistico-2022-2023-capitulo-2" component={Capitulo222} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="*" component={RouterNotas} />
      </Switch>
      <Footer />
    </>
      );
  }
  
  export default Master;