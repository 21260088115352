import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import '../../../../assets/css/notasGeneral.css';

import encabezado from '../../../../assets/images/sitio/10_BOLG/amarillas/blog_canimolt_15nov_amarillo__.png';

export const ImportanciaConsumirGranos = () => {
  return (
    <>
    <Helmet>
        <meta name="description" content="" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Divulgación-CIMMYT"/>
        <meta name="author" content="Divulgación-CIMMYT" />
        <meta property="og:title" content="La importancia de consumir granos enteros" />
        <meta property="og:description" content="El 19 de noviembre se celebra el Día Internacional del Grano Entero. La ingesta de cereales integrales no solo aporta diversos beneficios a la nutrición y salud humana, también representa una contribución a la producción sostenible de alimentos. " />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="CANIMOLT" />
        <meta property="og:image" content="" />
        <meta property="og:image:secure_url" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="El 19 de noviembre se celebra el Día Internacional del Grano Entero. La ingesta de cereales integrales no solo aporta diversos beneficios a la nutrición y salud humana, también representa una contribución a la producción sostenible de alimentos. " />
        <meta name="twitter:title" content="La importancia de consumir granos enteros" />
        <meta name="twitter:image" content="" />
      </Helmet>
      <Row>
      <Col span={24} className="banner_seccion_blog">
        <p className="titulo_blog_banner">¡BIENVENIDO!</p>
        <p className="texto_blog_banner">¡Conozca más sobre el trigo, el cual ha alimentado a la humanidad por más de 4 mil años!</p>
      </Col>
      <Col span={20} offset={2}>
        <p className="nuestro_blog">Nuestro Blog</p>
      </Col>
      <Col xs={{span:18, offset:3}} md={{span:12, offset:2}} >
        <img src={encabezado} alt="" className="imge_encabezado_nota_blog" />
      </Col>
      <Col xs={{span:18, offset:3}} md={{span:12, offset:2}} className="contenedor_nota_texto">
        <p className="titulo_nota">La importancia de consumir granos enteros</p>
        <p className="nota_texto">
        El 19 de noviembre se celebra el Día Internacional del Grano Entero. La ingesta de cereales integrales no solo aporta diversos beneficios a la nutrición y salud humana, también representa una contribución a la producción sostenible de alimentos. 
        <br /><br />
        Por: Divulgación-CIMMYT<br />
        15 de noviembre de 2021
        <br /><br />
        Texcoco, Edo. Méx.- Los cereales proporcionan gran parte de las necesidades energéticas y nutricionales de los seres humanos. De hecho, se estima que proporcionan más de la mitad de las calorías a nivel global y son fuente importante de carbohidratos y fibra dietética, así como de micronutrientes y proteínas de origen vegetal. No obstante, la importancia de los alimentos a base de cereales, especialmente de los alimentos hechos con granos enteros, está siendo socavada por una gran cantidad de sitios que, sin fundamento científico,  afirman que estos alimentos son innecesarios o poco saludables.
        <br /><br />
        Desde el punto de vista nutricional, las personas que omiten los alimentos a base de cereales carecerán de la fibra que estos aportan y pueden tener una ingesta inadecuada de micronutrientes. La recomendación entonces es no omitir estos alimentos y, sobre todo, integrar a la dieta alimentos hechos con granos enteros. 
        <br /><br />
        Los granos refinados se diferencian de los granos enteros en que algunas o todas las capas externas de salvado (la “cáscara” de las semillas) se eliminan durante la molienda, el perlado (cuando se pule el grano para eliminar el salvado) o la eliminación de germen (la parte más pequeña que constituye el núcleo de la semilla). Estos procesos reducen los micronutrientes y disminuyen significativamente la fibra dietética, tanto, que a los granos refinados y enriquecidos se les tiene que agregar micronutrientes para reemplazar algunas de estas pérdidas y en algunos países incluso los granos refinados tienen que ser fortificados obligatoriamente.
        <br /><br />
        Una de las preguntas más comunes es si se debe optar por consumir solo alimentos de grano entero o si es posible (y saludable) consumir tanto alimentos de grano entero como de grano refinado. Para brindar un panorama claro y confiable sobre este tema, un conjunto de investigadores de la Universidad St. Catherine de Estados Unidos, de la Universidad de Córdoba de España, y del Centro Internacional de Mejoramiento de Maíz y Trigo (CIMMYT) de México, desarrollaron un estudio para buscar evidencia (en análisis clínicos sobre el tema en los últimos 20 años) que respaldaran las recomendaciones de que los consumidores deben hacer que al menos la mitad de sus granos sean integrales.
        <br /><br />
        Los investigadores señalan que los estudios epidemiológicos y sus metanálisis muestran consistentemente que aquellas personas que ingieren la mayor cantidad de alimentos hechos con grano entero (en comparación con aquellos que los ingieren mínimamente o no los ingieren), están asociados con menores riesgos de mortalidad temprana general o por causas específicas y enfermedades crónicas. Específicamente, señala el estudio, los alimentos de grano entero se asocian con menores riesgos de obesidad o sobrepeso; tolerancia anormal a la glucosa, prediabetes y diabetes tipo 2; presión arterial o lípidos en sangre; enfermedad coronaria y cardiovascular; y ciertos cánceres.
        <br /><br />
        El estudio señala también que no se deben ignorar las contribuciones nutricionales de los granos refinados y enriquecidos a la ingesta de hierro, ácido fólico y vitamina B. Además, agrega, “la combinación de alimentos de grano entero y alimentos de granos refinados contribuye a resultados saludables (…) Los granos refinados pueden hacer que los alimentos de grano entero sean más aceptables y, por lo tanto, mejorar la ingesta de grano entero. La inclusión de alimentos a base de granos puede ayudar al cumplimiento de la dieta a largo plazo y crear un cambio dietético sostenible al contribuir con proteínas de origen vegetal, fibra dietética y nutrientes en formas aceptables, familiares, asequibles y no perecederas”. 
        <br /><br />
        En el marco del Día Internacional del Grano Entero (19 de noviembre) te invitamos a leer el artículo completo que está disponible en este enlace: Perspective: Whole and Refined Grains and Health—Evidence Supporting “Make Half Your Grains Whole”.
        </p>
        <br /><br /><br />
      </Col>
    </Row>
    </>
  );
}

export default ImportanciaConsumirGranos;