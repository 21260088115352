import React, { useState } from 'react';
import { Row, Col, button } from 'antd';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Errores from '../../paginas/Errores';
import '../../../assets/css/home.css';
import { guardar_mail } from '../../../utils/ConexionServidor';
import axios from "axios";

import { useHistory } from "react-router-dom";


import img_preguntas from '../../../assets/images/sitio/banner/canimolt_BannersCenso_INFOGRAFIA.jpg';
import img_censoptt from '../../../assets/images/sitio/banner/canimolt_BannersCenso_PPT.jpg';
import img_censoweb from '../../../assets/images/sitio/banner/canimolt_BannersCenso_WEBDIRECTA.jpg';
import img_completo from '../../../assets/images/sitio/banner/banner_compendio_completo_.jpg';

import infografia from '../../../assets/images/sitio/DOCUMENTOS/Infografia_sectorial_INEGI.pdf';
import panorama from '../../../assets/images/sitio/DOCUMENTOS/Panorama_CE24CANIMOLT.pdf';

import { Element } from 'react-scroll';

const BannerCapitulos = () => {

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <>
       <Slider {...settings} style={{marginTop:'0px', paddingtop:'0px',marginBottom:'-6px', paddingBotton:'0px'}}>
        <div>
            <Link to={infografia} target='_blank'><img src={img_preguntas} style={{ width: '100%' }} alt="" /></Link>
          </div>
        <div>
          <Link to={panorama} target='_blank'><img src={img_censoptt} style={{ width: '100%' }} alt="" /></Link>
          </div>
          <div>
          <a href="https://censoseconomicos2024.mx/ " target='_blank'><img src={img_censoweb} style={{ width: '100%' }} alt="" /></a>
          </div>
          
    </Slider>
    
        </>
    );
}

export default BannerCapitulos;