import React, { useEffect, useState } from 'react';
import { Row, Col, Anchor, Modal, Form, notification, Input } from 'antd';
import Errores from './Errores';
import { guardar_mail } from '../../utils/ConexionServidor';
import axios from "axios";

import { useHistory } from "react-router-dom";

import '../../assets/css/publicaciones.css';

const Compendio = () => {
    console.log('local', localStorage.status)

    const [modal_mail, setModalMail] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, seterror] = useState('');
    const history = useHistory();

    const mensaje = (tipo, titulo) => {
        notification[tipo]({
            message: titulo,
            duration: 10,
        });
    }

    const onFinish = async values => {
        values.type = 'Capitulo 1';
        guardar_mail(values).then(({ data, status, success }) => {
            switch (success) {
                case true:
                    localStorage.setItem('email', data.email);
                    localStorage.setItem('status1', success);

                    setModalMail(false);
                    break;
                default:
                    mensaje('error', 'Advertencia');
                    break;
            }
        });

    };

    useEffect(() => {
        if (localStorage.status1 != 'true') {
            setModalMail(true);
        } else {
            setModalMail(false);
        }
        

    });

    useEffect(() => {
        const cleanup = () => {
          // do your cleanup
          localStorage.removeItem('status');
          localStorage.clear();
        }
        window.addEventListener('beforeunload', cleanup);
        return () => {
            window.removeEventListener('beforeunload', cleanup);
        }
        }, []);
    
    return (
        <Row>
            <Col span={24} id="revista">

                <p className="titulo_revista_reportes">
                    Compendio Estadístico 2017 - 2021

                </p>
            </Col>
            <Col span={20} offset={2}><br />
                <div style={{ position: 'relative', paddingTop: 'max(60%,326px)', height: '0', width: '100%' }}><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox" allowfullscreen="true" style={{ position: 'absolute', border: 'none', width: '100%', height: '100%', left: '0', right: '0', top: '0', bottom: '0' }} src="https://e.issuu.com/embed.html?backgroundColor=%23ffffff&d=web-canimolt-cestad2017a2021&hideIssuuLogo=true&hideShareButton=true&u=canimolt"></iframe></div>
                <br /><br />
            </Col>
            <Modal
                visible={modal_mail}
                onOk={() => setModalMail(false)}
                onCancel={() => setModalMail(false)}
                closable={false}
                title="Para visualizar el documento ingresa un correo"
                footer={null}
                style={{}}
                bodyStyle={{}}>
                <Form className="login-form" onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="email"
                        label={<span style={{ color: '#000000' }}>Correo electrónico</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa tu correo!',
                                type: 'email'
                            },
                        ]}
                    >
                        <Input style={{ backgroundColor: '#F5F8F5' }} />
                    </Form.Item>
                    {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                    <Form.Item>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>

                                <button htmlType="submit" class="btn-amarillo boton-amarillo">
                                    Entrar
                                </button>

                            </Col>
                        </Row>

                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}

export default Compendio;
