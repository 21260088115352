import React, { useState } from 'react';
import { Row, Col, button } from 'antd';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import flecha_derecha from '../../../assets/images/sitio/1_HOME/iconoflecha_derecha.png';
import flecha_izquierda from '../../../assets/images/sitio/1_HOME/iconoflecha_izq.png';


//import trimex_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_bebeagua.png';
import trimex_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_diez lustros.png';
//import trimex_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_euskaro.png';
import trimex_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_flores.png';
import trimex_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_fresa.png';
import trimex_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_grano de plata.png';
import trimex_img7 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_guadalupana.png';
import trimex_img8 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_harinamedalladeoro.png';
import trimex_img9 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_hojadeoro.png';
import trimex_img10 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_lidia.png';
import trimex_img11 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_Marfil.png';
import trimex_img12 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_Medalla de plata.png';
import trimex_img13 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_Medalladeoro panificable.png';
import trimex_img14 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_medalladeoro_c1frances.png';
import trimex_img15 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_sacoF.png';
import trimex_img16 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_san marcos asmsa.png';
import trimex_img17 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_saratoga.png';
import trimex_img18 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_seisaspas.png';
import trimex_img19 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_silao.png';
import trimex_img20 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_Suprema S.png';
import trimex_img21 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_suprema50.png';
import trimex_img22 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_supremacompleta.png';
import trimex_img23 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_tacurina.png';
import trimex_img24 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_victoria.png';
import trimex_img25 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_xaley.png';
import trimex_img26 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/trimex_yoreda.png';
import trimex_molinera_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_chamizal.png';
import trimex_molinera_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_eldiluvio.png';
import trimex_molinera_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_mayran.png';
//import trimex_molinera_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_selecta harinatorillas.png';
//import trimex_molinera_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_selecta pastel.png';
import trimex_molinera_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_selecta.png';
//import trimex_molinera_img7 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_selectaPan.png';
//import trimex_molinera_img8 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/GRUPO_TRIMEX/MOLINERA/molinera_selectaTortilla.png';
import trimex_espiga_img1 from '../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png';

import elizondo_img23 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/hojadeplata_harinadetrigo.png';
import elizondo_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/maite_harinadetrigo.png';
import elizondo_img13 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/elizondo_harinadetrigo.png';
import elizondo_img9 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/osasuna_harinadetrigo.png';
import elizondo_img27 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/leyre_harinadetrigo.png';
import elizondo_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/alaska_harinadetrigo.png';
import elizondo_img7 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/altaproteina_harinadetrigo.png';
import elizondo_img8 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/nube_harina de trigo.png';
import elizondo_img15 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/ensenada_harinadetrigo.png';
import elizondo_img19 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/harinaintegral_harinadetrigo.png';
import elizondo_img18 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/semoladetrigo.png';
import elizondo_img12 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/salvadilloespecial.png';
import elizondo_img14 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/salvadodetrigo.png';
import elizondo_img20 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/trigorasgado.png';
import elizondo_img17 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/granodetrigo.png';
import elizondo_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/acemitadetrigo.png';


//import elizondo_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/logo_harinaselizondo.png';
//import elizondo_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/maite_harinadetrigo(1).png';
//import elizondo_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/altaproteina_harinadetrigo(1).png';
//import elizondo_img10 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/salvadilloespecial(1).png';
//import elizondo_img11 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/elizondo_harinadetrigo(1).png';
//import elizondo_img16 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/semoladetrigo(1).png';
//import elizondo_img21 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/hojadeplata_harinadetrigo(1).png';
//import elizondo_img22 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/unica_extrafina_harinadetrigo(1).png';
//import elizondo_img24 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/unica_extrafina_harinadetrigo.png';
//import elizondo_img25 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/leyre_harinadetrigo(1).png';
//import elizondo_img26 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINAS_ELIZONDO/unica_tortillas.png';


import anahuac_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_ANAHUAC/ANAHUAC_independecia_maxima.png';
import anahuac_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_ANAHUAC/ANAHUAC_independeciatortilla.png';
import anahuac_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_ANAHUAC/ANAHUAC_multiusos.png';
import anahuac_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_ANAHUAC/ANAHUAC_superindependencia.png';
import chiapas_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_Montebello_trigoestandar.png';
import chiapas_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_aguaazul_trigosuave_10.png';
import chiapas_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_aguaazul_trigosuave_44.png';
import chiapas_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_flordechaiapas_trigosuave_10.png';
import chiapas_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_flordechiapas_trigofuerte.png';
import chiapas_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_montebello_trigosuave_10.png';
import chiapas_img7 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_sancristobalTortillas.png';
import chiapas_img8 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_sancristobalplus.png';
import chiapas_img9 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_CHIAPAS/chiapas_sancrsitobal.png';
import oriente_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DE_ORIENTE/lisy.jpg';
import oriente_img2 from '../../../assets/images/sitio/6_productos_socios/oriente/maya_.jpg';
import oriente_img3 from '../../../assets/images/sitio/6_productos_socios/oriente/laloba.jpg';
import oriente_img4 from '../../../assets/images/sitio/6_productos_socios/oriente/elobo.jpg';
import oriente_img5 from '../../../assets/images/sitio/6_productos_socios/oriente/virrey.jpg';

import deusto_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DEUSTO/deusto.png';
import deusto_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DEUSTO/purepecha_deusto.png';
import deusto_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DEUSTO/extrafina_salmantina_deusto.png';
import deusto_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DEUSTO/reinavictoria_deusto.png';
import deusto_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DEUSTO/integral_salmantina_deusto.png';
import deusto_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_DEUSTO/santarosa_deusto.png';
import guadalupe_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_celio.png';
import guadalupe_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_dorada.png';
import guadalupe_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_doradaextrablanca.png';
import guadalupe_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_doradaplus.png';
import guadalupe_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_jimena.png';
import guadalupe_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_optima.png';
import guadalupe_img7 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_oro.png';
import guadalupe_img8 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_GUADALUPE/guadalupe_panaderia.png';

import tlanepantla_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_TLANEPANTLA/harina_alpesa.png';
//import tlanepantla_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_TLANEPANTLA/harina_alpesaespecial.png';
import tlanepantla_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_TLANEPANTLA/harina_atica.png';
//import tlanepantla_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_TLANEPANTLA/harina_aticaT.png';
import tlanepantla_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_TLANEPANTLA/harina_estandar.png';
//import tlanepantla_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/HARINERA_TLANEPANTLA/harina_integral.png';

import moderna_img1 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_angelito.png';
import moderna_img2 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_cisnedeoro.png';
import moderna_img3 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_laperla.png';
import moderna_img4 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_laperlaintegrl.png';
import moderna_img5 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_lirio.png';
import moderna_img6 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_pavo.png';
import moderna_img7 from '../../../assets/images/sitio/6_PRODUCTOS SOCIOS/LA_MODERNA/moderna_rendidora.png';


import parayas_img1 from '../../../assets/images/sitio/6_productos_socios/parayas/espumadechapala.png';
import parayas_img2 from '../../../assets/images/sitio/6_productos_socios/parayas/inmaculada.png';
import parayas_img3 from '../../../assets/images/sitio/6_productos_socios/parayas/logo_donmanuel.png';
import parayas_img4 from '../../../assets/images/sitio/6_productos_socios/parayas/parayas.png';

const productos = [
  //{img:trimex_img1,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img2,url:'/productos-socios#trimex',index:Math.random()},
  //{img:trimex_img3,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img4,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img5,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img6,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img7,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img8,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img9,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img10,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img11,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img12,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img13,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img14,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img15,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img16,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img17,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img18,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img19,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img20,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img21,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img22,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img23,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img24,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img25,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_img26,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_molinera_img1,url:'/productos-socios#trimex_molinera',index:Math.random()},
  {img:trimex_molinera_img2,url:'/productos-socios#trimex_molinera',index:Math.random()},
  {img:trimex_molinera_img3,url:'/productos-socios#trimex_molinera',index:Math.random()},
  //{img:trimex_molinera_img4,url:'/productos-socios#trimex',index:Math.random()},
  //{img:trimex_molinera_img5,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_molinera_img6,url:'/productos-socios#trimex_molinera',index:Math.random()},
  //{img:trimex_molinera_img7,url:'/productos-socios#trimex',index:Math.random()},
  {img:trimex_espiga_img1,url:'/productos-socios#trimex_espiga',index:Math.random()},
  {img:elizondo_img1 ,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img2,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img3,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img4,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img5,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img6,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img7,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img8,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img9,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img10,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img11,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img12,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img13,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img14,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img15,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img16,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img17,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img18,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img19,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img20,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img21,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img22,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img23,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img24,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img25,url:'/productos-socios#elizondo',index:Math.random()},
  //{img:elizondo_img26,url:'/productos-socios#elizondo',index:Math.random()},
  {img:elizondo_img27,url:'/productos-socios#elizondo',index:Math.random()},

  {img:anahuac_img1 ,url:'/productos-socios#anahuac',index:Math.random()},
  {img:anahuac_img2,url:'/productos-socios#anahuac',index:Math.random()},
  {img:anahuac_img3,url:'/productos-socios#anahuac',index:Math.random()},
  {img:anahuac_img4,url:'/productos-socios#anahuac',index:Math.random()},

  {img:chiapas_img1 ,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img2,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img3,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img4,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img5,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img6,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img7,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img8,url:'/productos-socios#chiapas',index:Math.random()},
  {img:chiapas_img9,url:'/productos-socios#chiapas',index:Math.random()},

  {img:oriente_img1,url:'/productos-socios#ho',index:Math.random()},
  {img:oriente_img2,url:'/productos-socios#ho',index:Math.random()},
  {img:oriente_img3,url:'/productos-socios#ho',index:Math.random()},
  {img:oriente_img4,url:'/productos-socios#ho',index:Math.random()},
  {img:oriente_img5,url:'/productos-socios#ho',index:Math.random()},

  {img:deusto_img1 ,url:'/productos-socios#deusto',index:Math.random()},
  {img:deusto_img2,url:'/productos-socios#deusto',index:Math.random()},
  {img:deusto_img3,url:'/productos-socios#deusto',index:Math.random()},
  {img:deusto_img4,url:'/productos-socios#deusto',index:Math.random()},
  {img:deusto_img5,url:'/productos-socios#deusto',index:Math.random()},
  {img:deusto_img6,url:'/productos-socios#deusto',index:Math.random()},

  {img:guadalupe_img1 ,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img2,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img3,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img4,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img5,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img6,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img7,url:'/productos-socios#guadalupe',index:Math.random()},
  {img:guadalupe_img8,url:'/productos-socios#guadalupe',index:Math.random()},

  {img:tlanepantla_img1 ,url:'/productos-socios#tlanepantla',index:Math.random()},
  //{img:tlanepantla_img2,url:'/productos-socios#tlanepantla',index:Math.random()},
  {img:tlanepantla_img3,url:'/productos-socios#tlanepantla',index:Math.random()},
  //{img:tlanepantla_img4,url:'/productos-socios#tlanepantla',index:Math.random()},
  {img:tlanepantla_img5,url:'/productos-socios#tlanepantla',index:Math.random()},
  //{img:tlanepantla_img6,url:'/productos-socios#tlanepantla',index:Math.random()},

  {img:moderna_img1 ,url:'/productos-socios#moderna',index:Math.random()},
  {img:moderna_img2,url:'/productos-socios#moderna',index:Math.random()},
  {img:moderna_img3,url:'/productos-socios#moderna',index:Math.random()},
  {img:moderna_img4,url:'/productos-socios#moderna',index:Math.random()},
  {img:moderna_img5,url:'/productos-socios#moderna',index:Math.random()},
  {img:moderna_img6,url:'/productos-socios#moderna',index:Math.random()},
  {img:moderna_img7,url:'/productos-socios#moderna',index:Math.random()},


  {img:parayas_img1,url:'/productos-socios#parayas',index:Math.random()},
  {img:parayas_img2,url:'/productos-socios#parayas',index:Math.random()},
  {img:parayas_img3,url:'/productos-socios#parayas',index:Math.random()},
  {img:parayas_img4,url:'/productos-socios#parayas',index:Math.random()},
]

const CarouselProductosSocios = () => {

  const ref_carousel_prodcuto = React.createRef();
  const ref_carousel_prodcuto_med = React.createRef();
  const ref_carousel_prodcuto_peq = React.createRef();
  const [activar_carousel, setActivarCarousel] = useState(0);
  const [activar_carousel_med, setActivarCarouselMed] = useState(0);
  const [activar_carousel_peq, setActivarCarouselPeq] = useState(0);

  const settings = {
    dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      arrows:false,
      
};
const settings_media = {
  dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows:false,
    
};
const settings_chico = {
  dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows:false,
    
};

  const cambiarCarousel = (adelante) => {
  if(adelante){
      if(activar_carousel === 2){
          setActivarCarousel(0);
          setActivarCarouselMed(0);
          setActivarCarouselPeq(0);
      }else{
          setActivarCarousel(activar_carousel+1);
          setActivarCarouselMed(activar_carousel+1);
          setActivarCarouselPeq(activar_carousel+1);
      }
      ref_carousel_prodcuto.current.slickNext();
      ref_carousel_prodcuto_med.current.slickNext();
      ref_carousel_prodcuto_peq.current.slickNext();
  }else{
      if(activar_carousel === 0){
          setActivarCarousel(2);
          setActivarCarouselMed(2);
          setActivarCarouselPeq(3);
      }else{
          setActivarCarousel(activar_carousel-1);
          setActivarCarouselMed(activar_carousel-1);
          setActivarCarouselPeq(activar_carousel-1);
      }
      ref_carousel_prodcuto.current.slickPrev();
      ref_carousel_prodcuto_med.current.slickPrev();
      ref_carousel_prodcuto_peq.current.slickPrev();
  }
  
}
  let ver_productos = productos.sort(function (a, b) {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }).map((logos)=>{
    return(
      <div>
        <Row justify="" align="middle">
          <Col span={24} className="contenedor_logo_producto">
            <Link to={logos.url}><img alt="" src={logos.img} className="carousel_productos" /></Link>
          </Col>
        </Row>
      </div>
    )
  })

  return (
    
    <>
    <Row>
      <Col md={{span:24, offset:0}} className="contenedor_banner_publicaciones">
        <Row>
          <Col xs={{span:20, offset:2}} md={{span:12, offset:6}}>
            <p className="titulo_publcaciones_home color_negro">PRODUCTOS SOCIOS</p>
          </Col>
          <Col md={{span:24, offset:0}}>
            <Row align="middle">
              <Col xs={0} md={0} lg={24} offset={0}>
                <div>
                  <Slider ref={ref_carousel_prodcuto} {...settings} style={{marginTop:'0px', paddingtop:'0px'}}>
                    {ver_productos}
                  </Slider>
                </div>
              </Col>
              <Col xs={0} md={24} lg={0} offset={0}>
                <div>
                  <Slider ref={ref_carousel_prodcuto_med} {...settings_media} style={{marginTop:'0px', paddingtop:'0px'}}>
                    {ver_productos}
                  </Slider>
                </div>
              </Col>
              <Col xs={{span:20, offset:2}} md={0} lg={0} offset={0}>
                <div>
                  <Slider ref={ref_carousel_prodcuto_peq} {...settings_chico} style={{marginTop:'0px', paddingtop:'0px'}}>
                    {ver_productos}
                  </Slider>
                </div>
              </Col>
              <Col span={4} offset={8} style={{textAlign:'right'}}>
                <img src={flecha_izquierda} className="img_carousel_flecha" alt="carousel_izquierda" onClick={() => cambiarCarousel(false)} />
              </Col>
              <Col span={4} offset={1} style={{textAlign:'left'}}>
                <img src={flecha_derecha} className="img_carousel_flecha" alt="carousel_derecha" onClick={() => cambiarCarousel(true)} />
              </Col>
              
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </>
  );
}

export default CarouselProductosSocios;