import React from 'react';
import { Row, Col } from 'antd';

const datos = [

    {nombre: "Energía (kcal)",avena:"389", trigo:"329", maiz:"365", arroz:"370", cebada:"354", centeno:"335"},
    {nombre: "Proteína (g)",avena:"16.09", trigo:"15.4", maiz:"9.4", arroz:"7.9", cebada:"12.5", centeno:"14.8"},
    {nombre: "Lípìdos (g)",avena:"6.9", trigo:"1.9", maiz:"4.7", arroz:"2.9", cebada:"2.3", centeno:"2.5"},
    {nombre: "Grasa saturada (g)",avena:"1.22", trigo:"0.31", maiz:"0.67", arroz:"0.58", cebada:"0.49", centeno:"0.29"},
    {nombre: "Grasa monoinsaturada (g)",avena:"2.18", trigo:"0.3", maiz:"1.25", arroz:"1.06", cebada:"0.3", centeno:"0.3"},
    {nombre: "Grasa polinsaturada (g)",avena:"2.54", trigo:"0.76", maiz:"2.16", arroz:"1.04", cebada:"1.11", centeno:"1.11"},
    {nombre: "Carbohidratos (g)",avena:"66", trigo:"68", maiz:"74", arroz:"77", cebada:"73", centeno:"70"},
    {nombre: "Calcio (mg)",avena:"54", trigo:"25", maiz:"7", arroz:"23", cebada:"33", centeno:"33"},
    {nombre: "Hierro (mg)",avena:"4.72", trigo:"3.6", maiz:"2.71", arroz:"1.47", cebada:"3.6", centeno:"2.7"},
    {nombre: "Sodio (mg)",avena:"2", trigo:"2", maiz:"35", arroz:"7", cebada:"12", centeno:"6"},
    {nombre: "Total fibra dietética (g)",avena:"10.3", trigo:"12.8", maiz:"13.5", arroz:"3.5", cebada:"17.3", centeno:"N/D"},
    {nombre: "Potasio (mg)",avena:"429", trigo:"340", maiz:"287", arroz:"223", cebada:"452", centeno:"264"},
    {nombre: "Magnesio (mg)",avena:"177", trigo:"124", maiz:"127", arroz:"143", cebada:"133", centeno:"121"},
    {nombre: "Fósforo (mg)",avena:"523", trigo:"332", maiz:"210", arroz:"333", cebada:"264", centeno:"374"},
    {nombre: "Zinc (mg)",avena:"3.97", trigo:"2.78", maiz:"2.21", arroz:"2.02", cebada:"2.77", centeno:"3.73"},
    {nombre: "Cobre (mg)",avena:"0.63", trigo:"0.4", maiz:"0.31", arroz:"0.28", cebada:"0.5", centeno:"0.45"},
    {nombre: "Manganeso (mg)",avena:"4.92", trigo:"4.05", maiz:"0.48", arroz:"3.74", cebada:"1.94", centeno:"2.68"},
    {nombre: "Tiamina (mg)",avena:"0.76", trigo:"0.5", maiz:"0.36", arroz:"0.4", cebada:"0.65", centeno:"N/D"},
    {nombre: "Riboflavina (mg)",avena:"0.14", trigo:"0.11", maiz:"0.2", arroz:"0.09", cebada:"0.29", centeno:"N/D"},
    {nombre: "Niacina (mg)",avena:"0.9", trigo:"5.71", maiz:"3.63", arroz:"5.09", cebada:"N/D", centeno:"N/D"},
    {nombre: "Ac. Pantoténico (mg)",avena:"1.35", trigo:"0.94", maiz:"0.42", arroz:"1.49", cebada:"N/D", centeno:"N/D"},
    {nombre: "Vitamina B6 (mg)",avena:"0.12", trigo:"0.34", maiz:"0.62", arroz:"0.51", cebada:"0.32", centeno:"0.29"},
    {nombre: "Ácido Fólico (mg)",avena:"56", trigo:"43", maiz:"N/D", arroz:"20", cebada:"19", centeno:"N/D"},
    {nombre: "Vitamina E (mg)",avena:"1.09", trigo:"1.01", maiz:"0.49", arroz:"0.88", cebada:"0.47", centeno:"1.28"}

];

const TablaPropiedades = () => {

    const tabla_datos = datos.map(dato => {
        return(
            <Row align="middle">
                <Col span={6} className="datos_menu_izquierdo back_amarillo">
                {dato.nombre}
                </Col>
                <Col span={3} className="datos color_gris" >
                    {dato.avena}
                </Col>
                <Col span={3} className="datos color_gris" >
                    {dato.trigo}
                </Col>
                <Col span={3} className="datos color_gris" >
                    {dato.maiz}
                </Col>
                <Col span={3} className="datos color_gris" >
                    {dato.arroz}
                </Col>
                <Col span={3} className="datos color_gris" >
                    {dato.cebada}
                </Col>
                <Col span={3} className="datos color_gris" >
                    {dato.centeno}
                </Col>
            </Row>
        );
    });

    return(

        <Row className="contenedor_tabla_ib">
            <Col span={22} offset={1}>
                <Row  className="contenedor_header_tabla_propiedades">
                    <Col span={6} className="datos_menu_superior">
                        NUTRIMENTO
                    </Col>
                    <Col span={3} className="datos_menu_superior">
                        AVENA
                    </Col>
                    <Col span={3} className="datos_menu_superior">
                        TRIGO
                    </Col>
                    <Col span={3} className="datos_menu_superior">
                        MAIZ
                    </Col>
                    <Col span={3} className="datos_menu_superior">
                        ARROZ (SILVESTRE)
                    </Col>
                    <Col span={3} className="datos_menu_superior">
                        CEBADA
                    </Col>
                    <Col span={3} className="datos_menu_superior">
                        CENTENO
                    </Col>
                </Row>
                { tabla_datos }
            </Col>
        </Row>

    );
}

export default TablaPropiedades;
