import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import '../../../../assets/css/notasGeneral.css';

import encabezado from '../../../../assets/images/sitio/10_BOLG/amarillas/alimentacion_nota_blog.png';

export const PlatoBienComer = () => {
  return (
    <>
    <Helmet>
        <meta name="description" content="" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content=""/>
        <meta name="author" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="CANIMOLT" />
        <meta property="og:image" content="" />
        <meta property="og:image:secure_url" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:image" content="" />
      </Helmet>
      <Row>
      <Col span={24} className="banner_seccion_blog">
        <p className="titulo_blog_banner">¡BIENVENIDO!</p>
        <p className="texto_blog_banner">¡Conozca más sobre el trigo, el cual ha alimentado a la humanidad por más de 4 mil años!</p>
      </Col>
      <Col span={20} offset={2}>
        <p className="nuestro_blog">Nuestro Blog</p>
      </Col>
      <Col xs={{span:18, offset:3}} md={{span:12, offset:2}} >
        <img src={encabezado} alt="" className="imge_encabezado_nota_blog" />
      </Col>
      <Col xs={{span:18, offset:3}} md={{span:12, offset:2}} className="contenedor_nota_texto">
        <p className="titulo_nota">EL PLATO DEL BIEN COMER</p>
        <p className="nota_texto">
        Fue diseñado para los mexicanos de acuerdo con nuestras características y necesidades.
        <br />
        Es una guía para lograr una alimentación correcta.
        <br />
        Nos ayuda a prevenir la obesidad, la hipertensión, ciertos tipos de cáncer y enfermedades del corazón.
        <br /><br />
        A todos nos hace sentir bien, vernos bien y tener energía para las actividades que realizamos cada día. Para lograr una alimentación correcta, es necesario conocer el Plato del Bien Comer.
        <br /><br />
        El plato califica a los alimentos en tres grupos de acuerdo con su función en la alimentación.
        <br /><br />
        Todos los grupos son importantes, ya que necesitamos de los tres para estar sanos:
        <br /><br />
        1. VERDURAS Y FRUTAS.
        <br />
        2. CEREALES Y TUBÉRCULOS.
        <br />
        3. LEGUMINOSAS Y PRODUCTOS DE ORIGEN ANIMAL.
        <br /><br /><br />
        1. VERDURAS Y FRUTAS:
        <br /><br />
        • Proporcionan vitaminas, minerales, agua y fibra.
        <br />
        • Ayudan a prevenir enfermedades entre las que se encuentran la obesidad, ciertos tipos de cáncer y las enfermedades del corazón.
        <br />
        • Se deben consumir de preferencia crudas y con cáscara, bien lavadas.
        <br />
        • Es conveniente consumir las de temporada, ya que son las de mejor calidad y las más baratas.
        <br /><br />
        2. PARA EL CASO DE LOS CEREALES:
        <br /><br />
        • Son fuente de energía, proporcionan hidratos de carbono, vitaminas, minerales y fibra. • Ejemplos de cereales: pan, tortillas, galletas, pasta, arroz, avena, cebada, amaranto.
        <br />
        • Se recomienda comerlos EN CANTIDAD SUFICIENTE.
        <br />
        • La combinación de cereales con leguminosas aporta proteínas de buena calidad.
        <br /><br />
        3. LEGUMINOSAS Y PRODUCTOS DE ORIGEN ANIMAL:
        <br /><br />
        • Proporcionan proteínas, vitaminas y minerales.
        <br />
        • Los alimentos de origen animal tienen un alto contenido de grasas saturadas y colesterol, por lo que se deben consumir en poca cantidad.
        <br />
        • Las leguminosas y oleaginosas no contienen colesterol.
        </p>
        <br /><br /><br />
      </Col>
    </Row>
    </>
  );
}

export default PlatoBienComer;