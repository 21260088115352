import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Error404 from '../error/Error404';

import PlatoBienComer from '../blog/notas/PlatoBienComer';
import ImportanciaConsumirGranos from './notas/ImportanciaConsumirGranos';
import BeneficiosAgricultura from './notas/BeneficiosAgricultura';

export const RouterNotas = () => {
    return(
        <Switch>
          <Route exact path="/la-importancia-de-consumir-granos-enteros" component={ImportanciaConsumirGranos} />
          <Route exact path="/plato-del-bien-comer" component={PlatoBienComer} />
          <Route exact path="/los-beneficios-de-la-agricultura-de-conservacion-de-la-voz-de-un-productor-de-trigo" component={BeneficiosAgricultura} />
            <Route path="*" component={Error404} />
        </Switch>
    );
} 
export default RouterNotas;