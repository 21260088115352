import React  from 'react';
import { Row, Col } from 'antd';
import { Element } from 'react-scroll';

import '../../../assets/css/cadenas.css';

import img_panificaion from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/icono_panificacion.png';
import img_producto_panificaion from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/panificacion_cereal.png';
import img_titu_pan from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/subtitulo_productospanaderia.png';
import img_producto_panificaion2 from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/panificacion_pandecaja.jpg';
import img_semola_pasta from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/icono_pasta.png';
import img_semola from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/semola.png';
import img_pasta from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/pasta.png';
import img_salvado_germen from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/icnoco_salvado_cereales.png';
import img_salvado from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/trigo_creales.png';
import img_germen from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/trigo.png';
import img_gluten from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/icono_gluten.png';
import img_mitos_gluten from '../../../assets/images/sitio/7_CADENAS_PRODUCTIVAS/gluten.png';


const CadenasProductivas = () => {
  return (
  <>
  <Row>
  
    <Col span={24} className="banner_panificaion">
    <Element name="#panificacion"></Element>
      <img alt="" src={img_panificaion} alt="" className="icono_banner" />
      <p id="panificacion" className="text_banner">
        Panificación
      </p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:12, offset:2}} className="contenedor_cadenas">
      <p className="titu_panificacion">Productos de panificación</p>
      <p className="text_definicion_cadenas">
      Se refieren a los obtenidos de las mezclas de harinas de cereales o harinas integrales o leguminosas, agua potable, fermentados o no, pueden 
      contener: sal comestible, mantequilla, margarina, aceites comestibles hidrogenados o no, leudante, polvo de hornear, especias y otros ingredientes 
      opcionales tales como, azúcares, mieles, frutas, jugos u otros productos comestibles similares, pueden emplear o no aditivos para alimentos; 
      sometidos a proceso de horneado, cocción o fritura; con o sin relleno o con cobertura, pueden ser mantenidos a temperatura ambiente, en 
      refrigeración o en congelación según el caso.
      </p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:8, offset:0}}>
      <img alt="" src={img_producto_panificaion} className="img_cadenas" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:16, offset:2}} className="text_definicion_cadenas">
      <img alt="" src={img_titu_pan} className="img_cadenas_titulo" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:8, offset:2}}>
      <img alt="" src={img_producto_panificaion2} className="img_cadenas" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:12, offset:0}} className="contenedor_cadenas">
      <p className="text_definicion_cadenas">
      Los obtenidos por procesos continuos de fabricación, estandarizados, con alto grado de automatización y en lotes de mayor escala. Pueden utilizar aditivos para alimentos y comercializarse tanto a granel como preenvasados.
      </p>
    </Col>
    
    <Col span={24} className="banner_pasta">
    <Element name="#semola"></Element>
      <img alt="" src={img_semola_pasta} className="icono_banner" />
      <p id="semola-pasta" className="text_banner">
        Sémola y pasta
      </p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:20, offset:2}} className="contenedor_cadenas">
      <p className="titu_panificacion">Sémola</p>
    </Col>
      <Col xs={{span:20, offset:2}} lg={{span:12, offset:2}} >
      <p className="text_definicion_cadenas">
      La sémola es una harina de gránulo grueso libre de tegumentos y germen, y es obtenida de la molienda del trigo maduro.<br /><br />
      La producción nacional de sémola se realiza con trigos cristalinos (durum) del noroeste de nuestro país. La industria molinera en el 2019 consumió poco más de 708,000 toneladas de trigo. Lo demás se exporta o se utiliza para forraje (porcicultores).
      </p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:8, offset:0}}>
      <img alt="" src={img_semola} className="img_cadenas" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:20, offset:2}} className="contenedor_cadenas">
      <p className="titu_panificacion">Pasta</p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:8, offset:2}}>
      <img alt="" src={img_pasta} className="img_cadenas" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:12, offset:0}} >
      <p className="text_definicion_cadenas">
      Es el producto que se obtiene por amasado mecánico de harina de trigo, sémola o semolina, o mezclas de éstas con agua, adicionada o no de glúten y huevo.<br /><br />
      Las pastas mexicanas cada día se colocan mejor en los mercados externos, en el año 2019 se vendieron más de 43,200 toneladas.<br /><br />
      La pasta en México es un producto que tiene expectativas de crecimiento en la exportación.
      </p>
    </Col>
   
    <Col span={24} className="banner_germen">
    <Element name="#salvado-germen"></Element>
      <img alt="" src={img_salvado_germen} className="icono_banner" />
      <p className="text_banner">
        Salvado y germen
      </p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:20, offset:2}}  className="contenedor_cadenas">
      <p className="titu_panificacion">Salvado, salvadillo, cema o acemite</p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:12, offset:2}}>
      <p className="text_definicion_cadenas">
      Estos cuatro productos surgen de la separación de la molienda del trigo. Están integrados por la cáscara del grano, mezclado con una pequeña porción de harina.<br /><br />
      Cada uno de ellos puede ser identificado por el grado de finura y contenido de harina. Estos productos son ricos en vitaminas, minerales y fibra, por ello se recomiendan en la dieta alimenticia.
      </p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:8, offset:0}}>
      <img alt="" src={img_salvado} className="img_cadenas" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:20, offset:2}}>
      <p className="titu_panificacion">Germen de trigo</p>
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:8, offset:2}}>
      <img alt="" src={img_germen} className="img_cadenas_trigo" />
    </Col>
    <Col xs={{span:20, offset:2}} lg={{span:12, offset:0}} className="contenedor_cadenas">
      <p className="text_definicion_cadenas">
      El grano de trigo, está formado por una cáscara exterior, una parte feculenta y el germen, que es la parte de la semilla que va a dar origen a una nueva planta.<br /><br />
      El germen de trigo es rico en proteínas, vitaminas B1, B2, B6, vitamina E y ácido fólico. (importante para evitar malformaciones).<br /><br />
      Aporta el 100% de los requerimientos de magnesio, zinc, cobre, y una parte importante de hierro y potasio.<br /><br />
      Es nutritivo, vigorizante y anti antianémico.<br /><br />
      Se enrancia fácilmente, por lo que debe guardarse en frascos oscuros y bien tapados.
      </p>
    </Col>
    <Col span={0} className="banner_gluten" id="gluten">
      <img alt="" src={img_gluten} className="icono_banner" />
      <p className="text_banner">
        Gluten
      </p>
    </Col>
    <Col xs={{span:0, offset:0}} lg={{span:0, offset:0}} className="contenedor_cadenas">
      <p className="titu_panificacion">Mitos y gluten</p>
      <p className="text_definicion_cadenas">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam .
Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam.<br />
Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam.
      </p>
    </Col>
    <Col xs={{span:0, offset:0}} lg={{span:0, offset:0}}>
      <img alt="" src={img_mitos_gluten} className="img_cadenas" />
    </Col>
  </Row>
  </>
  );
}

export default CadenasProductivas;