import React, { useState } from 'react';
import { Row, Col, button, Button } from 'antd';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import flecha_derecha from '../../../assets/images/sitio/1_HOME/iconoflecha_derecha.png';
import flecha_izquierda from '../../../assets/images/sitio/1_HOME/iconoflecha_izq.png';
import img_mision from '../../../assets/images/sitio/1_HOME/mision.png';
import img_vision from '../../../assets/images/sitio/1_HOME/vision.png';
import img_quienes from '../../../assets/images/sitio/1_HOME/quienessomos.png';

const BannerHistoria = () => {

  const ref_carousel_sabias_que = React.createRef();
  const [activar_sabias_que, setActivarSabiasQue] = useState(0);

  const settings = {
    dots: false,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false
  };
  
  const cambiarCarousel = (adelante) => {
  if(adelante){
      if(activar_sabias_que === 2){
          setActivarSabiasQue(0);
      }else{
          setActivarSabiasQue(activar_sabias_que+1);
      }
      ref_carousel_sabias_que.current.slickNext();
  }else{
      if(activar_sabias_que === 0){
          setActivarSabiasQue(2);
      }else{
          setActivarSabiasQue(activar_sabias_que-1);
      }
      ref_carousel_sabias_que.current.slickPrev();
  }
  
  }

  return (
    <>
    <Row>
      <Col xs={{span:24, offset:0}} className="contenedor_banner_historia">
        <Row>
          <Col xs={{span:20, offset:2}} lg={{span:12, offset:6}}>
            <p className="titulo_historia color_amarillo">NUESTRA HISTORIA</p>
            <p className="desc_historia color_blanco">La Cámara Nacional de la Industria Molinera de Trigo (CANIMOLT) es una organización creada con base en la Ley de Cámaras 
              Empresariales y sus Confederaciones para representar y defender los intereses legítimos de los industriales molineros, 
              fabricantes de harinas y sémolas de trigo.<br /> <br /></p>
          </Col>
          <Col md={{span:22, offset:1}}>
            <Row align="middle">
              <Col xs={0} lg={1} style={{textAlign:'right'}}>
                <img src={flecha_izquierda} className="img_carousel_flecha" alt="carousel_izquierda" onClick={() => cambiarCarousel(false)} />
              </Col>
              <Col xs={{span:21, offset:1}} lg={{span:20, offset:1}}>
                <div>
                  <Slider ref={ref_carousel_sabias_que} {...settings} style={{marginTop:'0px', paddingtop:'0px'}}>
                    <div>
                        <Row align="middle">

                          <Col xs={{span:22}} lg={{span:14}}>
                            <img src={img_vision} className="img_carousel" alt="vision" />
                          </Col>
                          <Col xs={{span:22}} lg={{span:10}} className="contenedor_texto_carousel">
                            <br /> <br />
                            <p className="carousel_titu">Visión</p>
                            <p className="carousel_text">Ser una organización cúpula modelo, que integre y represente a todos los sectores y segmentos productivos de la cadena del trigo, capaz de generar ideas y aportar propuestas para mejorar su competitividad.</p>
                            <p className="contenedor_boton_banner historia_slider">
                            <br /><br />
                            <Link to="/conocenos#vision" className="btn-negro boton-negro">LEER MÁS</Link>
                            
                            </p>
                          </Col>
                        </Row>
                    </div>
                    <div>
                      <Row align="middle">
                        <Col xs={{span:22}} lg={{span:14}}>
                          <img src={img_mision} className="img_carousel" alt="vision" />
                        </Col>
                        <Col xs={{span:22}} lg={{span:10}} className="contenedor_texto_carousel">
                        <br /> <br />
                          <p className="carousel_titu">Misión</p>
                          <p className="carousel_text">Promover, orientar, fomentar y prestar servicios al sector industrial molinero de trigo, haciendo todo tipo de gestiones ante autoridades federales, estatales o locales para solicitar la expedición, modificación o derogación de leyes, reglamentos o cualquier disposición jurídica que permita proteger el desarrollo de la industria. Procura, a su vez, la unión, coordinación y cooperación de las empresas que integran el sector.
                          <br />
                          
                          </p>
                          <p className="contenedor_boton_banner historia_slider">
                          <Link to="/conocenos#mision" className="btn-negro boton-negro">LEER MÁS</Link>
                            </p>
                            
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row align="middle">
                        <Col xs={{span:22}} lg={{span:14}}>
                          <img src={img_quienes} className="img_carousel" alt="vision" />
                        </Col>
                        <Col xs={{span:22}} lg={{span:10}} className="contenedor_texto_carousel">
                        <br /> <br />
                          <p className="carousel_titu">¿Quiénes somos?</p>
                          <p className="carousel_text">La Cámara Nacional de la Industria Molinera de Trigo (CANIMOLT) es una organización creada con base en la Ley de Cámaras Empresariales y sus Confederaciones para representar y defender los intereses legítimos de los industriales molineros, fabricantes de harinas y sémolas de trigo.</p>
                          <p className="contenedor_boton_banner historia_slider">
                          <Link to="/conocenos#quienes-somos" className="btn-negro boton-negro">LEER MÁS</Link>
                            </p>
                        </Col>
                      </Row>
                    </div>
                  </Slider>
                </div>
              </Col>
              <Col xs={{span:4, offset:8}} lg={0} style={{textAlign:'right'}}>
                <img src={flecha_izquierda} className="img_carousel_flecha" alt="carousel_izquierda" onClick={() => cambiarCarousel(false)} />
              </Col>
              <Col xs={{span:4}} lg={{span:1, offset:1}} style={{textAlign:'left'}}>
                <img src={flecha_derecha} className="img_carousel_flecha" alt="carousel_derecha" onClick={() => cambiarCarousel(true)} />
              </Col>
              <Col span={24}></Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </>
  );
}

export default BannerHistoria;