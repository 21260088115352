import React from 'react';
import { Row, Col } from 'antd';

import bandera_mexico from '../../../assets/images/sitio/4_trigo/bandera_mexico.png';
import bandera_eeuu from '../../../assets/images/sitio/4_trigo/bandera_eua.png';
import bandera_canada from '../../../assets/images/sitio/4_trigo/bandera_canada.png';
import trigo_mexico from '../../../assets/images/sitio/4_trigo/imgn_mexicoycereales.png';
import { Element } from 'react-scroll';

const datos_mexico = [
    {
        grupo:'GRUPO1', flexibilidad:'Fuerte (muy elástico) y extensible.', durabilidad:'Duro a semiduro.', info:'Lo utiliza la industria mecanizada de la panificación, produciendo principalmente harina para pan de caja. Se le utiliza como mejorador de trigos débiles.'
    },
    {
        grupo:'GRUPO2', flexibilidad:'Medio fuerte (elástico) y extensible.', durabilidad:'Duro a semiduro.', info:'Es para la industria del pan hecho a mano o semi-mecanizado; se le utiliza como mejorador de trigos débiles o trigos con gluten muy fuerte.'
    },
    {
        grupo:'GRUPO3', flexibilidad:'Débil (ligeramente elástico) y extensible.', durabilidad:'Suave (blando). No producen harinas panificables por sí solos; requieren mezclarse con trigos Grupo 1 y 2.', info:'Se utilizan para la industria galletera y elaboración de tortillas, buñuelos y otros; aunque puede utilizarse en la panificación artesanal. Como corrector de trigos con gluten muy fuerte.'
    },
    {
        grupo:'GRUPO4', flexibilidad:'Medio y tenaz (no extensible)', durabilidad:'Duro a semiduro.', info:'No es panificable por su alta tenacidad. Se mezcla con trigos fuertes. Es utilizado para la industria de la repostería (pastelera y galletera).'
    },
    {
        grupo:'GRUPO5', flexibilidad:'Fuerte, tenaz y corto (no extensible).', durabilidad:'Es un grano muy duro y cristalino. Endospermo con alto contenido de pigmento amarillo (carotenoides)', info:'No es panificable. Se usa para la industria de pastas alimenticias (espagueti, macarrones, sopas secas, etc.).'
    },
];

const datos_eeuu = [
    {
        nombre:'HARD RED SPRING (HRS). TRIGO DURO DE PRIMAVERA', accion:'Se siembra en los estados de Dakota del Norte, Dakota del Sur, Minnesota y Montana.', texto:'Este trigo es sembrado en primavera y es un importante trigo para panificación, que posee el más alto contenido proteico, usualmente 13 a 14% en adición a sus buenas características molineras y panificadoras. Las subclases se basan en el contenido de granos oscuros, duros y vítreos e incluyen el dark northern spring y red spring. Se utiliza para la fabricación de pan de molde, masas para pizza, croissants, pizza y panecillos.'
    },
    {
        nombre:'HARD RED WINTER(HRW). TRIGO DURO ROJO DE INVIERNO.', accion:'Es cultivado en Texas, Oklahoma, Kansas, Colorado y California.', texto:'Es un trigo importante que se usa para la panificación. El 40% de la producción de trigo de los E.U.A. es de este tipo y representa casi el 40% de las exportaciones. Tiene un alto contenido proteínico promedio de 9.5%, es rico en gluten con buenas cualidades para la molienda. No existe ninguna subclase para esta variedad. Se utiliza para la fabricación de pan con levadura de alta calidad y panecillos.'
    },
    {
        nombre:'HARD WHITE (HW).', accion:'Es cultivado en California, Idaho, Kansas y Montana.', texto:'Se siembra en otoño y primavera. Su contenido proteico es de 10 a 15%, y tampoco cuenta con subclases. Se utiliza en la fabricación de deos, frituras, panes de levadura y panes planos.'
    },
    {
        nombre:'SOFT RED WINTER(SRW).', accion:'Se cultiva en el oriente de los Estados Unidos.', texto:'Es un trigo de alto rendimiento pero bajo en proteínas, usualmente el 10%. Se siembra en otoño y es usado principalmente para pastelería, panes sin levaduras, galletas cracker y bocadillos (snacks). Comprende el 17% de la producción de E.U.A. y el 17% de las exportaciones.'
    },
    {
        nombre:'SOFT WHITE(SW).', accion:'Se cultiva en Washington, Oregon, Idaho.', texto:'Es sembrado en otoño y primavera y es el preferido para panes sin levadura, pastelería, galletas y deos chinos. Es un trigo bajo en proteínas, usualmente cerca del 10%. Las subclases son el soft white, white club y western white.'
    },
];

const datos_canada = [
    {
        nombre:'CANADA WESTERN AMBER DURUM (CWAD).', texto:'Es el segundo trigo más cultivado en el Oeste de Canadá. Sus mejores clases tienen más granos duros vítreos, especialmente desarrollados para producir un porcentaje alto de sémola, con gluten fuerte. Este trigo se utiliza principalmente para la elaboración de deos y cuscus.'
    },
    {
        nombre:'CANADA WESTERN EXTRA STRONG RED SPRING.', texto:'Es el trigo de uso general y es más duro que el Canada Western Red Spring. Es de media proteína y proporciona gran fuerza a la masa, así como una mayor estabilidad. Es utilizado para mezclas en pan de molde, bollos, y productos similares.'
    },
    {
        nombre:'CANADA WESTERN RED SPRING (CWRS).', texto:'Es la variedad de trigo más importante del oeste de Canadá, ya que constituye aproximadamente un 60% del total de acres sembrados. Su alto contenido proteínico es de 11.5 a 13.5%, por lo que resulta ideal para la molienda y panificación. Su atributo más importante es la calidad para la mezcla, uniformidad y disponibilidad. Es utilizada para la producción de pan tipo francés, deos y harinas para pan sin levadura.'
    },
    {
        nombre:'CANADA WESTERN RED WINTER (CWRW).', texto:'Es un grano de media proteína. Es ideal para la elaboración de cierto tipo de deos.'
    },
];


const TablasTipoGranoPais = () => {

    const visualizar_mexico = datos_mexico.map(datos => {
        
        return(
            <Row align="middle" className="contenedor_tabla_trigo_pais">
            <Col span={3}><p className="texto_nombre_tipos_grano">{datos.grupo}</p></Col>
            <Col span={4}><p className="texto_textos_tipos_grano">{datos.flexibilidad}</p></Col>
            <Col span={4}><p className="texto_textos_tipos_grano">{datos.durabilidad}</p></Col>
            <Col span={13}><p className="texto_textos_tipos_grano">{datos.info}</p></Col>
        </Row>
        );
    });

    const visuzalizar_eeuu = datos_eeuu.map(datos => {

        return(
            <Row align="middle" className="contenedor_tabla_trigo_pais">
                <Col span={5}><p className="texto_nombre_tipos_grano">{datos.nombre}</p></Col>
                <Col span={5}><p className="texto_textos_tipos_grano">{datos.accion}</p></Col>
                <Col span={14}><p className="texto_textos_tipos_grano">{datos.texto}</p></Col>
            </Row>
        );
    });

    const visuzalizar_canada = datos_canada.map(datos => {

        return(
            <Row align="middle" className="contenedor_tabla_trigo_pais">
                <Col span={6}><p className="texto_nombre_tipos_grano">{datos.nombre}</p></Col>
                <Col span={18}><p className="texto_textos_tipos_grano">{datos.texto}</p></Col>
            </Row>
        );
    });

    return(
        <Row>
            <Col span={24} className="contenedor_bandera">
                <p className="contenedor_bandera_pais"><img alt="" src={bandera_mexico} className="bandera_pais" /></p>
                <span className="contenedor_texto_pais"> TRIGOS EN MÉXICO</span>
            </Col>
            <Col span={24}>
                
                {visualizar_mexico}
            </Col>
            <Col span={24}>
            <br /><br />
            <Row className="back_amarillo">
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                    <p className="texto_titulo_trigo">
                        EL TRIGO EN MÉXICO
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
                    <p className="texto_info_trigo color_gris">

                        <img alt="" src={trigo_mexico} className="img_trigo_mexico" />

                        Se dice que el trigo llegó a nuestro país en
                        la época de la conquista, a través de
                        embarcaciones españolas que arribaron
                        con grandes cantidades de trigo, pero la
                        historia lo documenta de otra manera.
                        <br /><br />
                        Como los viajes del viejo mundo a América
                        eran largos, las provisiones se consumían y
                        terminaban antes de llegar a su destino. Al
                        parecer, los viajeros no se preocupaban por
                        guardar algunas semillas para que fueran
                        sembradas en México. Por eso se dice que
                        fue un poco tardía la llegada del trigo a
                        nuestro país.
                        <br /><br />
                        Según relato de los historiadores Andrés de Tapia y Francisco López de Gómora, el negro portugués
                        Juan Garrido, criado de Hernán Cortés fue el primero en sembrar y cosechar el primer trigo en México
                        al encontrar mezclados tres granos en un costal de arroz. <strong>Solo germinó uno que dio 180 granos y
                            de esa espiga se hicieron otras siembras que comenzaron a cultivarse en diferentes regiones
                            de la Nueva España.</strong>
                        <br /><br />
                        Ya para 1534, a escasos 13 años de consolidar la conquista, se levantaban importantes cosechas de
                        trigo en las inmediaciones de <strong>Texcoco y Puebla.</strong>
                        <br /><br />
                        Los jesuitas hicieron que el trigo llegara a la parte norte del país en donde enseñaron a los nativos a
                        cultivarlo. Con la expulsión de estos religiosos, los franciscanos siguieron la labor del cultivo en toda
                        la región.
                        <br /><br />
                        El cultivo del trigo, así como su transformación en harina y pan en la Nueva España, fue una
                        necesidad imperiosa de los conquistadores, para satisfacer aquí viejas costumbres en su
                        alimentación. También tuvieron la tarea de enseñar a los autóctonos la molienda y la elaboración del
                        pan convirtiéndose en parte de la dieta americana desde entonces.
                        <br /><br />
                        Los molinos cercanos a la capital, algunos de los cuales databan del siglo XVI, eran a principios del
                        siglo XIX los siguientes: <strong>El Molino del Rey, que pertenecía al Marqués de Zulueta; los de
                            Temacoco, Zavaleta, Socorro, Miraflores en Texcoco y el del Moral; los de Santo Domingo y
                            Valdés en Coyoacán y el de Belén en las lomas de Santa Fé, en Tacubaya; los de Santa Mónica y
                            San Ildefonso en la jurisdicción de Azcapotzalco.</strong>

                    </p>
                </Col>
            </Row>
            </Col>
            <Col span={24} className="contenedor_bandera">
            
                <p className="contenedor_bandera_pais"><img alt="" src={bandera_eeuu} className="bandera_pais" /></p>
                <span className="contenedor_texto_pais"> TRIGOS EN ESTADOS UNIDOS</span>
            </Col>
            <Col span={24}>
            
                {visuzalizar_eeuu}
            </Col>

            <Col span={24} className="contenedor_bandera">
                <p className="contenedor_bandera_pais"><img alt="" src={bandera_canada} className="bandera_pais" /></p>
                <span className="contenedor_texto_pais"> TRIGOS EN CANADÁ</span>
            </Col>
            <Col span={24}>
                {visuzalizar_canada}
            </Col>

        </Row>
    );
}

export default TablasTipoGranoPais;
