import React, { useRef, useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { Row, Col } from 'antd';
import { Element, scroller } from 'react-scroll';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import flecha_derecha from '../../../assets/images/sitio/2_CONOCENOS/flechas_bca-der.png';
import flecha_izquierda from '../../../assets/images/sitio/2_CONOCENOS/flechas_bca-izq.png';

import img_quienes_somos from '../../../assets/images/sitio/2_CONOCENOS/imgn-quienessomos.jpg';
import img_mision from '../../../assets/images/sitio/2_CONOCENOS/imgn-mision.jpg';
import img_vision from '../../../assets/images/sitio/2_CONOCENOS/imgn-vision.jpg';

const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    adaptiveHeight: true
  };

const CarouselConocenos = () => {

    const ref_carousel_sabias_que = useRef(null);
    const [activar_sabias_que, setActivarSabiasQue] = useState(0);

    const cambiarCarousel = (adelante) => {
        if(adelante){
            if(activar_sabias_que === 2){
                setActivarSabiasQue(0);
            }else{
                setActivarSabiasQue(activar_sabias_que+1);
            }
            ref_carousel_sabias_que.current.slickNext();
        }else{
            if(activar_sabias_que === 0){
                setActivarSabiasQue(2);
            }else{
                setActivarSabiasQue(activar_sabias_que-1);
            }
            ref_carousel_sabias_que.current.slickPrev();
        }    
    }
    const location = useLocation();

    

    useEffect(() => {
        switch(location.hash){
            case "#mision":
                ref_carousel_sabias_que.current.slickGoTo(0);
                break;
            case "#vision":
                ref_carousel_sabias_que.current.slickGoTo(1);
                break;
            case "#quienes-somos":
                ref_carousel_sabias_que.current.slickGoTo(2);
                break;
                
        }
    });

    return(
        <Row className="contenedor_inicio_conocenos">
            <Col span={24}>
                <Slider ref={ref_carousel_sabias_que} {...settings} style={{marginTop:'0px', paddingtop:'0px'}}>
                    <div id="mision">
                        <Row>
                            <Col xs={{span:20, offset:2}} md={{span:10, offset:2}} >
                            <Element name="#mision"></Element>
                                <p className="titulo_inicio_cocnocenos cream">MISIÓN</p>
                                <p className="texto_inicio_cocnocenos">
                                    <span className="color_amarillo"><strong>Promover, orientar, fomentar y prestar servicios a las empresas del 
                                        sector industrial molinero de trigo,</strong></span>  haciendo todo tipo de gestiones ante autoridades federales, estatales o 
                                        locales, para solicitar la expedición, modificación o derogación de leyes, reglamentos o cualquier disposición 
                                        jurídica que proteja el adecuado desarrollo de las industrias. 
                                        <br /><br />
                                    <span className="color_amarillo"><strong>Además, debe procurar la unión, coordinación y cooperación de las empresas, así 
                                        como contribuir con su desarrollo y fortalecimiento,</strong></span> en donde la Cámara se constituya en el principal punto de encuentro, y 
                                        los empresarios expongan sus problemas y necesidades; propongan soluciones y las medidas a ejercer, ante las autoridades, 
                                        organismos y empresas que faciliten el desarrollo integral del sector.
                                        <br /><br />
                                    <span className="color_amarillo"><strong>La unidad industrial, es elemento esencial para la toma de decisiones que deberán ser enfocadas a una 
                                        planeación de largo plazo, </strong></span> que estimule el crecimiento de la industria y su cadena productiva, bajo los principios de libertad de mercados, donde 
                                        la competencia sea un elemento que genere eficiencia, competitividad y bienestar a la sociedad y a las empresas en su conjunto.

                              </p>
                              
                          </Col>
                          <Col xs={{span:20, offset:2}} md={{span:10, offset:0}} className="contenedor_lado_derecho_inicio">
                            <p className="palabra_conocecnos_inicio">CONÓCENOS</p>
                            <p className="contenedor_img_inicio_conocenos">
                                <img alt="" src={img_mision} className="img_inicio_conocenos" />
                            </p>
                            
                          </Col>
                        </Row>
                    </div>
                    <div id="vision">
                        <Row align="top">

                          <Col xs={{span:20, offset:2}} md={{span:10, offset:2}} >
                          <Element name="#vision"></Element>
                              <p className="titulo_inicio_cocnocenos cream">VISIÓN</p>
                              
                              <p className="texto_inicio_cocnocenos">
                              • Ser el mejor modelo de organización privada, <span className="color_amarillo"><strong>cuyos principios éticos deben incluir la integración y protección</strong></span> de todos los integrantes de la cadena productiva del trigo, capaz de generar ideas y aportar propuestas para mejorar la competitividad de todos.
                              <br /><br />
                              • <span className="color_amarillo"><strong>Contribuir a remover los obstáculos y crear condiciones</strong></span> para desarrollar la competitividad, la capacidad comercial y exportadora del sector productivo, aprovechando las oportunidades y sopesando los riesgos de la apertura comercial.
                              <br /><br />
                              • Divulgar ante la sociedad, las distintas formas mediante las cuales el sector productivo <span className="color_amarillo"><strong>contribuye al mejoramiento de la salud, el combate a la anemia, desnutrición y obesidad.</strong></span>
                              <br /><br />
                              • <span className="color_amarillo"><strong>Promover la competencia real y leal entre las empresas,</strong></span> acorde a los principios de libre mercado, que contribuya a generar bienestar para todos, mediante un diálogo abierto, constante y permanente, interno y externo, sin caer en prácticas competitivas ilegales.
                              <br /><br />
                              • Promover el diálogo social y la concertación política para orientar a los consumidores, y <span className="color_amarillo"><strong>buscar acuerdos que contribuyan al beneficio social y el desarrollo de la propia industria.</strong></span>


                              </p>
                        
                           
                          </Col>
                          <Col xs={{span:20, offset:2}} md={{span:10, offset:0}} className="contenedor_lado_derecho_inicio">
                          
                            <p className="palabra_conocecnos_inicio">CONÓCENOS</p>
                            <p className="contenedor_img_inicio_conocenos">
                                <img alt="" src={img_vision} className="img_inicio_conocenos" />
                            </p>
                            
                          </Col>
                        </Row>
                    </div>
                    <div id="quienes-somos">
                        <Row align="top ">

                            <Col xs={{span:20, offset:2}} md={{span:10, offset:2}} >
                            <Element name="#quienes-somos"></Element>
                                <p className="titulo_inicio_cocnocenos cream">¿QUIÉNES SOMOS?</p>
                                <p className="texto_inicio_cocnocenos">
                                <span className="color_amarillo"><strong>La Cámara Nacional de la Industria Molinera de Trigo (CANIMOLT)</strong></span> es una organización creada con base en la Ley de Cámaras Empresariales y sus Confederaciones para representar y defender los intereses legítimos de los industriales molineros, fabricantes de harinas y sémolas de trigo.
                                <br /><br />
                                Es una institución de interés público, autónoma, con <span className="color_amarillo"><strong>personalidad jurídica y patrimonio propios distintos de los de sus socios</strong></span> y, por mandato de dicha ley, es un órgano de consulta y colaboración obligada del Estado Mexicano.
                                <br /><br />
                                <span className="color_amarillo"><strong>CANIMOLT fue fundada el 31 de enero del 2005</strong></span> como resultado de la integración de 6 Cámaras y Asociaciones regionales molineras cuya mayor antigüedad se remonta a 1948.
                                <br /><br />
                                Es el único vocero del sector molinero de trigo, pues no existe otro gremio igual. <span className="color_amarillo"><strong>Desde 1982, es socio fundador de la Asociación Latinoamericana de Industriales Molineros (ALIM),</strong></span> y busca ser una organización líder en el ámbito empresarial mexicano y latinoamericano bajo una visión de altos valores éticos, morales y de responsabilidad social.
                                <br /><br />
                                CANIMOLT representa a toda la industria harinera de trigo, ostenta el <span className="color_amarillo"><strong>80%</strong></span> de la producción real de los molinos, y afilia a más del <span className="color_amarillo"><strong>70%</strong></span> de las empresas molineras del país, pues atiende a 63 plantas industriales integradas de todos los tamaños, concentradas en <span className="color_amarillo"><strong>33</strong></span> empresas ubicadas en todo el territorio nacional.
                                <br /><br />
                                <span className="color_amarillo"><strong>CANIMOLT orgullosamente promueve y defiende a una de las industrias alimenticias más antiguas del continente americano,</strong></span> ya que su origen data desde los primeros años de la conquista, habiendo llegado el trigo, por primera vez, a nuestro Continente, a través de México.
                                <br /><br />
                                En la actualidad, la Cámara Molinera Nacional trabaja con sus socios bajo misión y visión muy diferentes a las de otras épocas donde las empresas fueron “reguladas” por el Estado a través de subsidios y el control de precios que, al final acababan encareciendo los productos al consumidor y gastando excesivos recursos públicos. Ahora, están comprometidos con las reglas de la competencia y el libre mercado, donde <span className="color_amarillo"><strong>la competitividad y la eficiencia, se convierten en condiciones que la han ido modernizando y consolidando.</strong></span>
</p>
                              
                          </Col>
                          <Col xs={{span:20, offset:2}} md={{span:10, offset:0}} className="contenedor_lado_derecho_inicio">
                          
                            <p className="palabra_conocecnos_inicio">CONÓCENOS</p>
                            <p className="contenedor_img_inicio_conocenos">
                                <img alt="" src={img_quienes_somos} className="img_inicio_conocenos" />
                            </p>
                            
                          </Col>
                        </Row>
                    </div>
                </Slider>
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:10, offset:2}}>
            <Row>
                                  <Col span={12}>
                                      <p className="contenedor_flecha_izquierda">
                                        <img src={flecha_izquierda} className="img_flecha_inicio_conocenos" alt="" onClick={() => {cambiarCarousel(false);}} />
                                      </p>
                                  </Col>
                                  <Col span={12}>
                                      <p className="contenedor_flecha_derecha">
                                        <img src={flecha_derecha} className="img_flecha_inicio_conocenos" alt="" onClick={() => {cambiarCarousel(true);}} />
                                      </p>
                                  </Col>
                              </Row>
            </Col>
        </Row>
    );

}

export default CarouselConocenos;
