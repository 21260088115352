import React, { useState } from 'react';
import { Row, Col, Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Cards from './Cards';

import '../../../assets/css/blog.css';

const Blog = () =>{

  const [categoria, verCategoria] =useState('todos');
  const [text_categoria, verTextoCategoria] = useState('Publicaciones');

  const handleMenuClick = (key) =>{
    console.log(key);
    verCategoria(key.key);
    verTextoCategoria(key.item.props.texto);
  }

  const menu_categorias = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="canimolt_de_interes" texto="Canimolt de interes" >Canimolt de interes</Menu.Item>
      <Menu.Item key="trigo" texto="Trigo" >Trigo</Menu.Item>
      <Menu.Item key="todos" texto="Todos" >Todos</Menu.Item>
    </Menu>
  );
  return(
    <Row>
      <Col span={24} className="banner_seccion_blog">
        <p className="titulo_blog_banner">¡BIENVENIDO!</p>
        <p className="texto_blog_banner">¡Conozca más sobre el trigo, el cual ha alimentado a la humanidad por más de 4 mil años!</p>
      </Col>
      <Col span={20} offset={2}>
        <p className="nuestro_blog">Nuestro Blog</p>
      </Col>
      <Col xs={{span:18, offset:3}} sm={{span:16, offset:4}} md={{span:0, offset:0}} className="contenedor_blog_movil">
        <Row justify="space-around" align="middle">
          <Col span={8} style={{ textAlign:'left' }}>
            <Dropdown overlay={menu_categorias}>
              <Button>
                Categorias <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col span={16}>
            <p className="opcion_text_categoria">{text_categoria}</p>
          </Col>
        </Row>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 14, offset: 2 }} className="contenedor_blog_web">
        <Cards categoria={categoria} />
      </Col>
      <Col xs={{ span: 0, offset: 0 }} md={{ span: 5, offset: 1 }} className="contenedor_blog_web">
        <Row>
          <Col className="contenedor_categorias opciones_categoria" span={24}>
            <p className="titulo_categorias">Categorías</p>
            
              <p className={categoria === 'canimolt_de_interes' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => verCategoria('canimolt_de_interes')}>CANIMOLT de interes</p>
              <p className={categoria === 'trigo' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => verCategoria('trigo')}>Trigo</p>
              <p className={categoria === 'todos' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => verCategoria('todos')}>Todos</p>
            
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default Blog;