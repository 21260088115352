import React from 'react';
import { Row, Col, Input, Form, Button } from 'antd';
import { Link } from 'react-router-dom';

import '../../../assets/css/footer.css';

import ico_ubicacion from '../../../assets/images/sitio/FOOTER/icono_ubicacion.png';
import ico_mail from '../../../assets/images/sitio/FOOTER/icono_mail.png';
import ico_tel from '../../../assets/images/sitio/FOOTER/icono_tel.png';

import logo_concamin from '../../../assets/images/sitio/FOOTER/logo-concamin_bco.png';
import logo_canimolt from '../../../assets/images/sitio/FOOTER/logoblanco-canimolt_footer.png';
import logo_agua from '../../../assets/images/sitio/FOOTER/logo_concamin-agua.png';


const Footer = () => {

  const onFinish = values => {
    console.log('Success:', values);
  };

  return (
    <Row justify="center" align="middle">
      <Col span={24} className="contenedor_footer">
        <Row justify="center" align="middle">
          <Col xs={20} md={7} className="contenedor_contacto">
            <img alt="" src={logo_canimolt} className="logo_canimolt_footer" />
            <img alt="" src={logo_agua} className="logo_canimolt_footer" /><br /><br />
            <p className="titulo_contacto myriad_semi">CONTACTO</p>
            <Row justify="center" align="middle">
              <Col span={4}>
                <p style={{textAlign:'right', margin: '0px'}}><img alt="" src={ico_ubicacion} className="icono_contacto" /></p>
              </Col>
              <Col span={20}>
                <p className="text_contacto">Insurgentes Sur 826-6, Del Valle, Benito Juárez, 03100, CDMX, México.</p>
              </Col>
              <Col span={4}>
                <p style={{textAlign:'right', margin: '0px'}}><img alt="" src={ico_mail} className="icono_contacto" /></p>
              </Col>
              <Col span={20}>
                <p className="text_contacto"><a className="link_footer" href="mailto:harinadetrigo@canimolt.org">harinadetrigo@canimolt.org</a></p>
              </Col>
              <Col span={4}>
                <p style={{textAlign:'right', margin: '0px'}}><img alt="" src={ico_tel} className="icono_contacto" /></p>
              </Col>
              <Col span={20}>
                <p className="text_contacto"><a className="link_footer" href="tel:+525555431814">+52 (55) 5543 1814</a></p>
              </Col>
            </Row>
          </Col>
          <Col xs={20} md={7} className="contenedor_contacto_2">
          <Row justify="center" align="middle">
            <Col span={18} className="contenedor_suscribete">
              <p className="texto_suscribete">SUSCRÍBETE</p>
              <Row justify="space-around" align="middle">
                <Col span={16}>
                  <input  className="input_suscribirse" placeholder="Email" />
                </Col>
                <Col span={8} className="contenedor_enviar_suscribirse">
                  <span className="enviar_suscribirse">
                    ENVIAR
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          </Col>
          <Col xs={20} md={7} className="contenedor_contacto_2">
            <Row justify="space-around" align="middle">
              <Col span={22} className="form_enviar_color">
              <Form 
                layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label={<span className="text_form_contacto">Nombre</span>}
        name="nombre"
        rules={[
          {
            required: true,
            message:<span className="text_form_contacto_error">Ingresa tu nombre</span>,
          },
        ]}
      >
        <Input className="input_contacto" />
      </Form.Item>
      <Form.Item
        label={<span className="text_form_contacto">Correo</span>}
        name="correo"
        rules={[
          {
            required: true,
            message:<span className="text_form_contacto_error">Ingresa tu correo</span>,
          },
        ]}
      >
        <Input className="input_contacto" />
      </Form.Item>
      <Form.Item
        label={<span className="text_form_contacto">Mensaje</span>}
        name="mensaje"
        rules={[
          {
            required: true,
            message:<span className="text_form_contacto_error">Escribe un mensaje</span>,
          },
        ]}
      >
        <Input.TextArea className="input_contacto" />
      </Form.Item>



      <Form.Item>
        <Button htmlType="submit" className="boton_enviar_mensaje" block><span className="enviar_mensaje_contacto">ENVIAR MENSAJE</span></Button>
      </Form.Item>
    </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="footer_derechos_contenedor">
        <p className="footer_derechos">COPYRIGHT © CÁMARA NACIONAL DE LA INDUSTRIA MOLINERA I Todos los derechos reservados I Organismo afiliado a :
        <img alt="" src={logo_concamin} className="logo_derechos" /></p>
      </Col>
    </Row>
  );
}

export default Footer;