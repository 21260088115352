import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router,
  Switch,
  Route } from 'react-router-dom';
import Master from './components/sitio/master/Master';
import 'antd/dist/antd.css';

function App() {

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 5 second!')
    }, 5000);
    return () => clearTimeout(timer);

  }, []);
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/*" component={Master} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
