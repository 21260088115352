import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import categoria_interes from './Interes.json';
import todos from './Todos.json';

import require1_ from '../../../assets/images/sitio/10_BOLG/amarillas/alimentacion_nota_blog.png';
import require2_ from '../../../assets/images/sitio/10_BOLG/amarillas/consumo_nota_blog.png';
import require3_ from '../../../assets/images/sitio/10_BOLG/amarillas/blog_canimolt_15nov_amarillo__.png';
import require4_ from '../../../assets/images/sitio/10_BOLG/amarillas/blog_beneficios_agricultura_canimolt.png';

const img_src_todos =[
  require1_,
  require2_,
  require3_,
  require4_
];

let categoria_seleccionada, img_src_categoria_seleccionada;

const Cards = (props) =>{

  const {categoria} = props;
  console.log(categoria);
   categoria_seleccionada = todos.filter(articulo =>{
      for (let i = 0; i < articulo.categorias.length; i++){
        if(articulo.categorias[i] == categoria){
          return true;
        }
      }
      return false;
   });

  img_src_categoria_seleccionada = img_src_todos;

  let cards_categorias = categoria_seleccionada.slice(0).reverse().map(categoria =>{
    return(
      <Col span={20} offset={2} className="contenedor_cards">
        <img src={img_src_categoria_seleccionada[categoria.img_index]} alt="" className="img_articulo_blog" />
        <br />
        <p class="titulo_articulo_blog">
          {categoria.titulo}
        </p>
        <p className="prev_articulo_blog">
          {categoria.info}
        </p>
        <p className="contenedor_boton_blog">
          <Link to={categoria.url} class="btn-blanco-blog boton-blanco-blog">LEER MÁS</Link>
        </p>
      </Col>
    )
  });

  return(
    <Row>
      {cards_categorias}
    </Row>
  );
}

export default Cards;