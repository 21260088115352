import React, { useRef, useState } from 'react';
import { Row, Col } from 'antd';
import CarouselConocenos from './CarouselConocenos';
import { Element } from 'react-scroll';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import flecha_derecha from '../../../assets/images/sitio/1_HOME/iconoflecha_derecha.png';
import flecha_izquierda from '../../../assets/images/sitio/1_HOME/iconoflecha_izq.png';

import logo_harinera_guadalupe from '../../../assets/images/sitio/2_CONOCENOS/logo harinera guadalupe.jpg';
import logo_harinera from '../../../assets/images/sitio/2_CONOCENOS/logo_harinera.jpg';
import logo_harinera_anahuac from '../../../assets/images/sitio/2_CONOCENOS/logo_harinera_anahuac.jpg';
import logo_harinera_ho from '../../../assets/images/sitio/2_CONOCENOS/logo_harinera_ho.jpg';
import logo_harienra_chiapas from '../../../assets/images/sitio/2_CONOCENOS/logo_harinerachiapas.jpg';
import logo_harinera_elizondo from '../../../assets/images/sitio/2_CONOCENOS/logo_harionaelizondo.jpg';
import logo_lamoderna from '../../../assets/images/sitio/2_CONOCENOS/logo_lamoderna.jpg';
import logo_trimex from '../../../assets/images/sitio/2_CONOCENOS/logo_trimex.jpg';
import logo_deusto from '../../../assets/images/sitio/2_CONOCENOS/lojo_deusto.jpg';


const settings = {
    dots: false,
    fade: false,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows:false
  };

  const settings_grande = {
    dots: false,
    fade: false,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows:false
  };

const CarouselSocios = () => {

    const ref_carousel_sabias_que = useRef(null);
    const ref_carousel_sabias_que_grande = useRef(null);
    const [activar_sabias_que, setActivarSabiasQue] = useState(0);
    const [activar_sabias_que_grande, setActivarSabiasQueGrande] = useState(0);

    const cambiarCarousel = (adelante) => {
        if(adelante){
            if(activar_sabias_que === 2){
                setActivarSabiasQue(0);
                setActivarSabiasQueGrande(0);
            }else{
                setActivarSabiasQue(activar_sabias_que+1);
                setActivarSabiasQueGrande(activar_sabias_que_grande+1);
            }
            ref_carousel_sabias_que.current.slickNext();
            ref_carousel_sabias_que_grande.current.slickNext();
        }else{
            if(activar_sabias_que === 0){
                setActivarSabiasQue(2);
                setActivarSabiasQueGrande(2);
            }else{
                setActivarSabiasQue(activar_sabias_que-1);
                setActivarSabiasQueGrande(activar_sabias_que_grande-1);
            }
            ref_carousel_sabias_que.current.slickPrev();
            ref_carousel_sabias_que_grande.current.slickPrev();
        }    
    }

    return(
        <>
        <Row className="contenedor_marcas_conocenos back_amarillo">
            <Col span={20} offset={2}>
            <Element name="#catalogo"></Element>
                <p className="titulo_marcas_conocenos" id="catalogo">CATÁLOGO DE MARCAS</p>
            </Col>
        </Row>
        <Row>
            <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                <Slider ref={ref_carousel_sabias_que} {...settings}>
                    <div> <a href="http://molinodeusto.com.mx/" target="_blank"><img alt="" src={logo_deusto} className="imagen_logo_grande" /></a></div>
                    <div> <a href="http://www.harineradechiapas.com.mx/" target="_blank"><img alt="" src={logo_harienra_chiapas} className="imagen_logo_grande" /></a></div>
                    <div> <a href="" target="_blank"><img alt="" src={logo_harinera} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://harineraanahuac.com.mx/" target="_blank"><img alt="" src={logo_harinera_anahuac} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://harinaselizondo.com/" target="_blank"><img alt="" src={logo_harinera_elizondo} className="imagen_logo_grande" /></a></div>
                    <div> <a href="http://www.harinera-guadalupe.com/" target="_blank"><img alt="" src={logo_harinera_guadalupe} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://www.harineradeoriente.mx/" target="_blank"><img alt="" src={logo_harinera_ho} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://www.lamoderna.com.mx/" target="_blank"><img alt="" src={logo_lamoderna} className="imagen_logo_grande" /></a></div>
                    <div> <a href="http://gtrimex.mx/" target="_blank"><img alt="" src={logo_trimex} className="imagen_logo_grande" /></a></div>
                </Slider>
            </Col>
            <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
                <Slider ref={ref_carousel_sabias_que_grande} {...settings_grande}>
                <div> <a href="http://molinodeusto.com.mx/" target="_blank"><img alt="" src={logo_deusto} className="imagen_logo_grande" /></a></div>
                    <div> <a href="http://www.harineradechiapas.com.mx/" target="_blank"><img alt="" src={logo_harienra_chiapas} className="imagen_logo_grande" /></a></div>
                    <div> <a href="" target="_blank"><img alt="" src={logo_harinera} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://harineraanahuac.com.mx/" target="_blank"><img alt="" src={logo_harinera_anahuac} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://harinaselizondo.com/" target="_blank"><img alt="" src={logo_harinera_elizondo} className="imagen_logo_grande" /></a></div>
                    <div> <a href="http://www.harinera-guadalupe.com/" target="_blank"><img alt="" src={logo_harinera_guadalupe} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://www.harineradeoriente.mx/" target="_blank"><img alt="" src={logo_harinera_ho} className="imagen_logo_grande" /></a></div>
                    <div> <a href="https://www.lamoderna.com.mx/" target="_blank"><img alt="" src={logo_lamoderna} className="imagen_logo_grande" /></a></div>
                    <div> <a href="http://gtrimex.mx/" target="_blank"><img alt="" src={logo_trimex} className="imagen_logo_grande" /></a></div>
                </Slider>
            </Col>
            <Col span={6} offset={6}>
                                      <p className="contenedor_flecha_izquierda">
                                        <img alt="" src={flecha_izquierda} className="img_flecha_inicio_conocenos" onClick={() => {cambiarCarousel(false);}} />
                                      </p>
                                  </Col>
                                  <Col span={6} offset={0}>
                                      <p className="contenedor_flecha_derecha">
                                        <img alt="" src={flecha_derecha} className="img_flecha_inicio_conocenos"  onClick={() => {cambiarCarousel(true);}} />
                                      </p>
                                  </Col>
        </Row>
        </>
    );
}

export default CarouselSocios;
