import React, {useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import CarouselPublicaciones from './CarouselPublicaciones';
import { Element } from 'react-scroll';

import '../../../assets/css/publicaciones.css';

const revistas = [
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=revistacanimolt_vol2008&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/revista/chicas/revista_marzo2008.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=revistacanimolt_vol_1_2008&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/revista/chicas/revista junio2008.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=revistacanimolt_vol_2_2008&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/revista/chicas/revista_sep 2008.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=revistacanimolt_vol_3_2008&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/revista/chicas/revista_dic2008.png')
    }
];

const reportes = [
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=reporteestadistico2010&hideIssuuLogo=true&hideShareButton=true&u=canimolt', img_url:require('../../../assets/images/sitio/publicaciones/reportes/chicas/reporte2010.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=reporte_estadistico_2012&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/reportes/chicas/reporte2012.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=reporte-estadistico-2014&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/reportes/chicas/reporte2014.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=reporte_estadi_stico_2015_2016&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/reportes/chicas/reporte2015-16.png')
    },
    {
        iframe_url:'https://e.issuu.com/embed.html?u=canimolt&d=canimolt_comprendioestadistico_17-21&hideIssuuLogo=true&hideShareButton=true&u=canimolt',img_url:require('../../../assets/images/sitio/publicaciones/reportes/chicas/miniatura_vista_compendio.png')
    },
];

const Publicaciones = () => {

    const [is_revista, setIsRevista] = useState(true);

    const location = useLocation();

    useEffect(() => {
        console.log(location.hash);
        switch(location.hash){
            case '#reportes': setIsRevista(false); break;
            default: setIsRevista(true); break;
        }
    },[location]);

    

    return(
        <Row>
            <Col span={24}>
            <Element name="#revista"></Element>
            <Element name="#reportes"></Element>
            </Col>
            {is_revista ? 
                (
                    <>
                    <Col span={24} id="revista">
                    
                        <p className="titulo_revista_reportes">
                            REVISTA
                        </p>
                    </Col>
                    <Col span={24}>
                        <div><CarouselPublicaciones publicaciones={revistas} /></div>
                    </Col>
                    </>
                )    
                :
                (
                    <>
                    <Col span={24} id="reportes">
                    
                        <p className="titulo_revista_reportes">
                            REPORTES ESTADÍSTICOS 
                        </p>
                    </Col>
                    <Col span={24}>
                        <CarouselPublicaciones publicaciones={reportes} />
                    </Col>
                    </>
                )
            }
        </Row>
    );
}

export default Publicaciones;
