import axios from 'axios';
const SERVER_URL = "https://api-sitio.canimolt.org";

const fallo_token = {
    success: false,
    data:{
        status: 5
    }
}

export const guardar_mail = async (data) => {

    const ENDPOINT = `${SERVER_URL}/api/downloads/`;
    console.log('data',data);
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar'
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}

export const guardar_mail_ = async (data) => {

    const ENDPOINT = `${SERVER_URL}/api/downloads`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}

export const permitir_entrada = async (data) => {

    const ENDPOINT = `${SERVER_URL}/webinar/permitir-entrada`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}
