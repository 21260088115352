import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Element } from 'react-scroll';

import '../../../assets/css/comercio.css';

import icono_baner_comercio from '../../../assets/images/sitio/9_COMERCIO_EXT_MARCO_NORM/icono_comercio exterior.png';
import icono_baner_marco from '../../../assets/images/sitio/9_COMERCIO_EXT_MARCO_NORM/icono_marco normativo.png';

import icono_trigo_n from '../../../assets/images/sitio/9_COMERCIO_EXT_MARCO_NORM/icono_trigo_negro.png';
import icono_link_n from '../../../assets/images/sitio/9_COMERCIO_EXT_MARCO_NORM/LINK_ICONO_NEGRO.png';

import crecimiento from '../../../assets/files/comercio_exterior_2/Crecimiento-del-Comercio-Mundial-2013-2020.pdf';
import tratados from '../../../assets/files/comercio_exterior_2/TLC-SUSCRITOS-MEX-PIB-POBLACION-2019.pdf';
import acuerdos from '../../../assets/files/comercio_exterior_2/ACE-AAP-2019.pdf';
import tratados_mexico from '../../../assets/files/comercio_exterior/TLC-SUSCRITOS-MEX.pdf';
import reglas from '../../../assets/files/comercio_exterior/Reglas-de-origen-aplicadas-en-los-tratados-comerciales.pdf';
import balanza from '../../../assets/files/comercio_exterior_2/Balanza-Comercial-Agropecuario-TLC-TLCUEM_Septiembre_2020.pdf';
import tarifa from '../../../assets/files/comercio_exterior_2/TIGIE-2017.pdf';

const comercio = [
  {titulo:'Crecimiento del Comercio Mundial 2013-2020', ver:crecimiento, descarga:crecimiento},
  {titulo:'Tratados de libre comercio suscritos por México', ver:tratados, descarga:tratados},
  {titulo:'Acuerdos de Complementación Económica (ACE) y Acuerdos de Alcance Parcial (AAP) suscritos por México', ver:acuerdos, descarga:acuerdos},
  {titulo:'Tratados y Acuerdos firmados por México', ver:tratados_mexico, descarga:tratados_mexico},
  {titulo:'Reglas de origen aplicadas', ver:reglas, descarga:reglas},
  {titulo:'Balanza Comercial del Comercio Agropecuario ( TLCAN-TLCUEM)', ver:balanza, descarga:balanza},
  {titulo:'Tarifa de los impuestos generales de importación y exportación (TIGIE) 2017', ver:tarifa, descarga:tarifa},
]
const marco = [
  {clave:'NOM-001-FITO-2001',titulo:'Campaña contra carbón parcial.',dof:'08/02/02',entrada:'09/02/02',modificacion:'08/02/02',url:'https://www.gob.mx/senasica/documentos/nom-001-fito-2001'},
  {clave:'NOM-005-FITO-1995',titulo:'Cuarentena contra el gorgojo khapra.',dof:'04/07/96',entrada:'05/07/96',modificacion:'09/04/17',url:'https://www.gob.mx/senasica/documentos/nom-005-fito-1995'},
  {clave:'NOM-017-FITO-1995',titulo:'Cuarentena contra plagas de trigo.',dof:'05/12/96',entrada:'06/12/96',modificacion:'05/12/96',url:'https://www.gob.mx/senasica/documentos/40982'},
  {clave:'NOM-034-FITO-1995',titulo:'Por la que se establecen los requisitos y especificaciones fitosanitarias para el aviso de inicio de funcionamiento que deberán cumplir las personas físicas o morales interesadas en la fabricación, formulación, formulación por maquila, formulación y/o maquila e importación de plaguicidas agrícolas.',dof:'24/06/96',entrada:'25/06/96',modificacion:'24/06/96',url:'https://www.gob.mx/senasica/documentos/nom-034-fito-1995'},
  {clave:'NOM-043-FITO-1999',titulo:'Especificaciones para prevenir la introducción de malezas cuarentenarias a México.',dof:'01/03/00',entrada:'02/03/00',modificacion:'01/03/00',url:'https://www.gob.mx/senasica/documentos/nom-043-fito-1999'},
  {clave:'NOM-052-FITO-1995',titulo:'Por la que se establecen los requisitos y especificaciones fitosanitarias para presentar el aviso de inicio de funcionamiento por las personas físicas o morales que se dediquen a la aplicación aérea de plaguicidas agrícolas.',dof:'10/06/97',entrada:'11/06/97',modificacion:'10/06/97',url:'https://www.gob.mx/senasica/documentos/nom-052-fito-1995'},
  {clave:'NOM-069-FITO-1995',titulo:'Para el establecimiento y reconocimiento de zonas libres de plagas.',dof:'18/11/98',entrada:'19/11/98',modificacion:'18/11/98',url:'https://www.gob.mx/senasica/documentos/nom-069-fito-1995'},
  {clave:'NOM-008-SCFI-2002',titulo:'Sistema general de unidades de medida.',dof:'27/11/02',entrada:'26/01/03',modificacion:'24/09/09',url:'http://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002'},
  {clave:'NOM-030-SCFI-2006',titulo:'Información comercial-declaración de cantidad en la etiqueta-especificaciones.',dof:'06/11/06',entrada:'06/01/07',modificacion:'06/11/06',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006'},
  {clave:'NOM-050-SCFI-2004',titulo:'Información comercial-etiquetado general de productos.',dof:'01/06/04',entrada:'31/07/04',modificacion:'01/06/04',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=708514&fecha=01/06/2004'},
  {clave:'NOM-051-SCFI/SSA1-2010',titulo:'Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas pre envasados, información comercial y sanitaria. (incluye regulación de eqtiuetado frontal desde 2015)',dof:'05/05/10',entrada:'05/01/11',modificacion:'30/06/15',url:'http://dof.gob.mx/nota_detalle.php?codigo=5137518&fecha=05/04/2010'},
  {clave:'NOM-086-SSA1-1994',titulo:'Bienes y servicios. Alimentos y bebidas no alcohólicas con modificaciones en su composición. Especificaciones nutrimentales.',dof:'26/06/96',entrada:'27/11/97',modificacion:'22/12/10',url:'http://www.economia-noms.gob.mx/normas/noms/1996/086-ssa1.pdf'},
  {clave:'NOM-130-SSA1-1995',titulo:'Bienes y servicios. Alimentos envasados en recipientes de cierre hermético y sometido a tratamiento térmico. Disposiciones y especificaciones sanitarias.(sin link)',dof:'21/11/97',entrada:'02/05/98',modificacion:'27/12/12',url:''},
  {clave:'NOM-187-SSA1/SCFI-2002',titulo:'Productos y servicios. Masa, tortillas, tostadas y harinas preparadas para su elaboración y establecimientos donde se procesan. Especificaciones sanitarias. Información comercial. Métodos de prueba.',dof:'18/08/03',entrada:'14/02/04',modificacion:'18/08/03',url:''},
  {clave:'NOM-232-SSA1-2009',titulo:'Artículos de alfarería vidriada, cerámica vidriada y plaguicidas: que establece los requisitos del envase, embalaje y etiquetado de productos grado técnico y para uso agrícola, forestal, pecuario, jardinería, urbano, industrial y doméstico.',dof:'13/04/10',entrada:'08/03/11',modificacion:'8/30/2017',url:'http://dof.gob.mx/nota_detalle.php?codigo=5139018&fecha=13/04/2010'},
  {clave:'NOM-247-SSA1-2008',titulo:'Productos y servicios. Cereales y sus productos. Cereales, harinas de cereales, sémolas o semolinas. Alimentos a base de: cereales, semillas comestibles, de harinas, sémolas o semolinas o sus mezclas. Productos de panificación. Disposiciones y especificaciones sanitarias y nutrimentales. Métodos de prueba.',dof:'27/07/09',entrada:'23/01/10',modificacion:'12/27/2012',url:''},
  {clave:'NOM-251-SSA1-2009',titulo:'Prácticas de higiene para el proceso de alimentos, bebidas o suplementos alimenticios.',dof:'01/03/10',entrada:'26/11/10',modificacion:'03/01/10',url:'http://dof.gob.mx/nota_detalle.php?codigo=5133449&fecha=01/03/2010'},
  {clave:'NOM-012-SCT-2-2017',titulo:'Sobre el peso y dimensiones máximas con los que pueden circular los vehículos de autotransporte que transitan en las vías generales de comunicación de jurisdicción federal.',dof:'26/12/17',entrada:'25/02/18',modificacion:'12/26/2017',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=5508944&fecha=26/12/2017'},
  {clave:'NOM-006-STPS-2017',titulo:'Manejo y almacenamiento de materiales-Condiciones de seguridad y salud en el trabajo.',dof:'18/09/17',entrada:'18/12/17',modificacion:'9/18/2017',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=5508931&fecha=26/12/2017'},
  {clave:'NOM-113-STPS-2009',titulo:'Seguridad - Equipo de protección personal - Calzado de protección - Clasificación, especificaciones y métodos de prueba.',dof:'22/12/09',entrada:'22/12/10',modificacion:'12/22/2009',url:'http://www.dof.gob.mx/normasOficiales/3924/stps1/stps1.htm'},
  {clave:'NOM-045-SEMARNAT-2006',titulo:'Protección ambiental.- Vehículos en circulación que usan diésel como combustible.- Límites máximos permisibles de opacidad, procedimiento de prueba y características técnicas del equipo de medición.',dof:'13/09/07',entrada:'12/11/07',modificacion:'12/06/12',url:'http://dof.gob.mx/nota_detalle.php?codigo=5000546&fecha=13/09/2007'},
  {clave:'NOM-047-SEMARNAT-2014',titulo:'Que establece las características del equipo y el procedimiento de medición para la verificación de los límites de emisión de contaminantes, provenientes de los vehículos automotores en circulación que usan gasolina, gas licuado de petróleo, gas natural u otros combustibles alternos.',dof:'26/11/2014',entrada:'25/01/15',modificacion:'11/26/2014',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=5371998&fecha=26/11/2014'},
  {clave:'NOM-081-SEMARNAT-1994',titulo:'Que establece los límites máximos permisibles de emisión de ruido de las fuentes fijas y su método de medición.',dof:'',entrada:'13/01/95',modificacion:'14/01/95',url:'1/14/1995'},
  {clave:'NOM-085-SEMARNAT-2011',titulo:'Contaminación atmosférica-Niveles máximos permisibles de emisión de los equipos de combustión de calentamiento indirecto y su medición.',dof:'02/02/12',entrada:'01/04/12',modificacion:'15/12/14',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=5232012&fecha=02/02/2012'},
  {clave:'NOM-167-SEMARNAT-2017',titulo:'Que establece los límites máximos permisibles de emisión de contaminantes para los vehículos automotores que circulan en las entidades federativas Ciudad de México, Hidalgo, Estado de México, Morelos, Puebla y Tlaxcala; los métodos de prueba para la evaluación de dichos límites y las especificaciones de tecnologías de información y hologramas.',dof:'05/09/17',entrada:'04/11/17',modificacion:'05/09/17',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=5496105&fecha=05/09/2017'},
]
const marco_dos = [
  {clave:'NMX-F-006-1983',titulo:'Alimentos - galletas.',dof:'16/02/83',entrada:'17/02/83',modificacion:'16/02/83',url:'http://www.colpos.mx/bancodenormas/nmexicanas/NMX-F-006-1983.PDF'},
  {clave:'NMX-F-007-1982',titulo:'Harina de trigo.',dof:'21/04/82',entrada:'22/04/82',modificacion:'21/04/82',url:'http://www.colpos.mx/bancodenormas/nmexicanas/NMX-F-007-1982.PDF'},
  {clave:'NMX-F-023-NORMEX-2002',titulo:'Alimentos-pasta-características, denominación, clasificación comercial y métodos de prueba.',dof:'23/09/02',entrada:'22/11/02',modificacion:'23/09/02',url:'https://www.canimolt.org/marco-normativo/'},
  {clave:'NMX-F-365-S-1980',titulo:'Harinas. Determinación de materia extraña.',dof:'03/02/81',entrada:'04/02/81',modificacion:'',url:'http://www.dof.gob.mx/nota_detalle.php?codigo=4610592&fecha=03/02/1981'},
  {clave:'NMX-F-442-1983',titulo:'Alimentos - pan - productos de bollería.',dof:'11/02/83',entrada:'12/02/83',modificacion:'11/02/83',url:'http://www.colpos.mx/bancodenormas/nmexicanas/NMX-F-442-1983.PDF'},
  {clave:'NMX-F-521-1992',titulo:'Alimentos - productos de panificación - clasificación y definiciones.',dof:'10/02/92',entrada:'11/02/92',modificacion:'10/02/92',url:'https://www.canimolt.org/marco-normativo/'},
  {clave:'NMX-FF-036-1996',titulo:'Productos alimenticios no industrializados. Cereales. Trigo. (triticum aestivum l y triticum durum desf.)',dof:'26/02/96',entrada:'27/02/96',modificacion:'26/02/96',url:'http://www.colpos.mx/bancodenormas/nmexicanas/NMX-FF-036-1996.PDF'},
  {clave:'NOM-FF-55-1984',titulo:'Productos Alimenticios no Industrializados Para uso Humano - Cereales - Trigo - Método de Prueba.',dof:'12/03/84',entrada:'13/02/81',modificacion:'12/03/84	',url:'http://www.colpos.mx/bancodenormas/nmexicanas/NMX-FF-055-1984.PDF'},
  {clave:'NMX-Y-010-1966',titulo:'Calidad para salvado de trigo - destinado a la alimentación de animales.',dof:'16/08/66',entrada:'17/08/66',modificacion:'',url:'http://dof.gob.mx/nota_detalle.php?codigo=2042778&fecha=09/05/2005'}

]
const ComercioMarco = () =>{
  const [mostrar_trigo_amarillo, mostrarTrigoAmarillo] = useState(false);

  let ver_comercio = comercio.map(publi =>{
    return (
      <Row style={{borderBottom:'1px solid #000000'}} align="middle" className="contenedor_todo">
        <Col xs={{span:0}} lg={{span:4}}>
          <img alt="" id="cambiar" src={icono_trigo_n} className="ico_trigo"/>
        </Col>
        <Col xs={{span:16}} lg={{span:12, offset:0}}>
          <p id="cambiar" className="titu_comercio">{publi.titulo}</p>
        </Col>
        <Col xs={{span:0}} lg={{span:4, offset:0}}>
          <p className="btns_comercio">
            <a target="_blank" href={publi.ver} className="btn_comercio">VISTA PREVIA</a>
          </p>
        </Col>
        <Col xs={{span:8}} lg={{span:4, offset:0}}>
          <p className="btns_comercio">
            <a target="_blank" href={publi.descarga} className="btn_comercio" download>DOWNLOAD</a>
          </p>
        </Col>
      </Row>
    )
  })

  let ver_marco = marco.map(normas =>{
    return(
      <Row style={{borderBottom:'1px solid #000000'}} align="middle" className="contenedor_todo">
        <Col xs={0} lg={{span:2,offset:0}}>
        <img alt="" id="cambiar" src={icono_trigo_n} className="ico_trigo"/>
      </Col>
      <Col  xs={{span:6, offset:0}} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.clave}</p>
      </Col>
      <Col xs={{span:16, offset:0}} lg={{span:8, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.titulo}</p>
      </Col>
      <Col xs={0} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.dof}</p>
      </Col>
      <Col xs={0} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.entrada}</p>
      </Col>
      <Col xs={0} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.modificacion}</p>
      </Col>
      <Col xs={{span:2, offset:0}} lg={{span:1, offset:0}}>
        <a target="_blank" href={normas.url}><img alt="" id="cambiar" src={icono_link_n} className="ico_link"/></a>
      </Col>
      </Row>
    )
  })

  let ver_marco_dos = marco_dos.map(normas =>{
    return(
      <Row style={{borderBottom:'1px solid #000000'}} align="middle" className="contenedor_todo">
        <Col xs={0} lg={{span:2,offset:0}}>
        <img alt="" id="cambiar" src={icono_trigo_n} className="ico_trigo"/>
      </Col>
      <Col  xs={{span:6, offset:0}} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.clave}</p>
      </Col>
      <Col xs={{span:16, offset:0}} lg={{span:8, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.titulo}</p>
      </Col>
      <Col xs={0} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.dof}</p>
      </Col>
      <Col xs={0} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.entrada}</p>
      </Col>
      <Col xs={0} lg={{span:3, offset:0}}>
        <p id="cambiar" className="titu_comercio">{normas.modificacion}</p>
      </Col>
      <Col xs={{span:2, offset:0}} lg={{span:1, offset:0}}>
        <a target="_blank" href={normas.url}><img alt="" id="cambiar" src={icono_link_n} className="ico_link"/></a>
      </Col>
      </Row>
    )
  })

  return(
    <>
    <Row>
      <Col span={24} className="banner_seccion_comercio">
      <Element name="#comercio"></Element>
        <p className="titulo_banner_seccion_comercio">COMERCIO EXTERIOR</p>

        <img alt="" className="icono_banner" src={icono_baner_comercio} /> 
      </Col>
      <Col span={22} offset={1} className="contenedor_seccion_comercio">
        {ver_comercio}
      </Col>
      
      <Col span={24} className="banner_seccion_marco">
      <Element name="#marco"></Element>
        <p className="titulo_banner_seccion_comercio">MARCO NORMATIVO</p>
        <img alt="" className="icono_banner" src={icono_baner_marco} /> 
      </Col>
      <Col span={22} offset={1}>
        <Row align="bottom" style={{ borderBottom:'6px solid #000000', paddingTop:'3%' }}>
          <Col xs={{span:0, offset:0}} lg={{span:2, offset:0}}></Col>
          <Col xs={{span:6, offset:0}} lg={{span:3, offset:0}}>
            <p className="cabeza_tabla_marco">CLAVE</p>
          </Col>
          <Col xs={{span:16, offset:0}} lg={{span:8, offset:0}}>
          <p className="cabeza_tabla_marco">TÍTULO</p>
          </Col>
          <Col xs={{span:0, offset:0}} lg={{span:3, offset:0}}>
          <p className="cabeza_tabla_marco">PUBLICACIÓN EN D.O.F</p>
          </Col>
          <Col xs={{span:0, offset:0}} lg={{span:3, offset:0}}>
          <p className="cabeza_tabla_marco">ENTRADA EN VIGOR</p>
          </Col>
          <Col xs={{span:0, offset:0}} lg={{span:3, offset:0}}>
          <p className="cabeza_tabla_marco">ÚLTIMA MODIFICACIÓN</p>
          </Col>
          <Col xs={{span:1, offset:0}} lg={{span:2, offset:0}}>
          <p className="cabeza_tabla_marco">URL</p>
          </Col>
        </Row>
      </Col>
      <Col span={22} offset={1}>
        {ver_marco}
      </Col>
      <Col span={22} offset={1}>
        <p className="texto_comercio">
          1. Son regulaciones técnicas para productos, procesos o servicios.<br />
          2. Son de carácter obligatorio.
          </p>
      </Col>
      <Col span={22} offset={1}>
        <Row align="bottom" style={{ borderBottom:'6px solid #000000', paddingTop:'3%' }}>
          <Col xs={{span:0, offset:0}} lg={{span:2, offset:0}}></Col>
          <Col xs={{span:6, offset:0}} lg={{span:3, offset:0}}>
            <p className="cabeza_tabla_marco">CLAVE</p>
          </Col>
          <Col xs={{span:16, offset:0}} lg={{span:8, offset:0}}>
          <p className="cabeza_tabla_marco">TÍTULO</p>
          </Col>
          <Col xs={{span:0, offset:0}} lg={{span:3, offset:0}}>
          <p className="cabeza_tabla_marco">PUBLICACIÓN EN D.O.F</p>
          </Col>
          <Col xs={{span:0, offset:0}} lg={{span:3, offset:0}}>
          <p className="cabeza_tabla_marco">ENTRADA EN VIGOR</p>
          </Col>
          <Col xs={{span:0, offset:0}} lg={{span:3, offset:0}}>
          <p className="cabeza_tabla_marco">ÚLTIMA MODIFICACIÓN</p>
          </Col>
          <Col xs={{span:1, offset:0}} lg={{span:2, offset:0}}>
          <p className="cabeza_tabla_marco">URL</p>
          </Col>
        </Row>
      </Col>
      <Col span={22} offset={1}>
        {ver_marco_dos}
      </Col>
      <Col span={22} offset={1}>
        <p className="texto_comercio">
        1. Establecen requisitos mínimos de calidad de productos y servicios.<br />
        2. Son de aplicación voluntaria y de referencia, a menos que los particulares manifiesten conformidad con una norma y sigan un proceso de acreditación o certificación.
        <br /><br />
          </p>
      </Col>
    </Row>
    </>
  );
} 
export default ComercioMarco;