import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../../assets/css/error.css';

import logo_404 from '../../../assets/images/sitio/11_ERROR_404/trigo.png';

const Error404 = () => {

    return(
        <>
        <Row>
          <Col span={24} className="contenedor_error404">
            <Row align="middle">
              <Col span={8} offset={8}>
                <img alt="" src={logo_404} className="logo_error" />
                <p className="texto_error">Lo sentimos, el enlace que seguiste se encuentra roto</p>
                <p className="contenedor_boton_error">
                  <Link to="/" class="btn-negro boton-negro">Volver al inicio</Link>
                            
                            </p>
              </Col>
            </Row>
          </Col>
        </Row>
        </>
    );
}

export default Error404;
