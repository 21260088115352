import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';

import '../../../assets/css/productos.css';

import { productosMarcas  } from './productos/productos_harineras';
import TablaHarinera from './TablaHarinera';


const ProductosSocios = () => {

    let location = useLocation();
    const [harinera, setHarinera] = useState([]);
    const [banner_logo, setBannerLogo] = useState([]);

    useEffect(() => {
        switch(location.hash){
            case '#anahuac': setHarinera(productosMarcas('anahuac')); 
                setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/anahuac/banner-harineraanahuac.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/anahuac/logos-harinera anahuac.png'),
                            'HARINERA ANÁHUAC S.A. DE C.V.']);
            break;
            case '#chiapas': setHarinera(productosMarcas('chiapas')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/chiapas/banner-harineradechiapas.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/chiapas/logos-harineradechiapas.png'),
                        'HARINERA DE CHIAPAS S.A. DE C.V.']);
                            break;
            case '#deusto': setHarinera(productosMarcas('deusto')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/deusto/banner-deusto.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/deusto/logos-deusto.png'),
                        'MOLINO DEUSTO']);
                            break;
            case '#elizondo': setHarinera(productosMarcas('elizondo')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/elizondo/banner-harinaselizondo(1).jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/elizondo/logo_harinaselizondo.png'),
                        'HARINAS ELIZONDO']);
                            break;
            case '#guadalupe': setHarinera(productosMarcas('guadalupe')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/guadalupe/banner-harineraguadalupe.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/guadalupe/logos-harineraguadalupe.png'),
                        'HARINERA GUADALUPE']);
                            break;
            case '#ho': setHarinera(productosMarcas('ho')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/oriente/banner-harineradeoriente.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/oriente/logos-logoharineradeoriente.png'),
                        'COMPAÑIA HARINERA DE ORIENTE S.A. DE C.V.']);
                            break;
            case '#tlanepantla': setHarinera(productosMarcas('tlanepantla')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/tlanepantla/banner-harineratlanepantla.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/tlanepantla/logos-harineratlanepantla.png'),
                        'HARINERA TLANEPANTLA S.A. DE C.V.']);
                            break;
            case '#trimex': setHarinera(productosMarcas('trimex')); 
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/trimex/banner-grupotrimex.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/trimex/logo_trimex.png'),
                        'GRUPO TRIMEX']);
                            break;
            case '#trimex_molinera': setHarinera(productosMarcas('trimex_molinera'));
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/trimex_molinera/banner-molinera.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/trimex_molinera/logo_molinera.png'),
                        'MOLINERA DE MÉXICO, S.A. DE C.V.']);
                            break;
            case '#trimex_espiga': setHarinera(productosMarcas('trimex_espiga'));
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/espiga/BANNER_ESPIGA.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/espiga/logobco_ESPIGA.png'),
                        'Molinera La Espiga']);
                            break;
            case '#real_altena': setHarinera(productosMarcas('real_altena'));
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/banner_real_socio.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/realaltena_logo_bco.png'),
                        'REAL ALTEÑA DERIVADOS DE TRIGO, S.A. DE C.V.']);
                                            break;
            case '#parayas': setHarinera(productosMarcas('parayas'));
            setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/parayas/BANNER_parayas.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/parayas/parayas_bannerlogo.png'),
                        'COMPAÑÍA HARINERA DEL PARAYAS']);
                                            break;
                                            
            default: setHarinera(productosMarcas('moderna')); 
                    setBannerLogo([require('../../../assets/images/sitio/6_productos_socios/la_moderna/banner-lamoderna.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/la_moderna/logos-lamoderna.png'),
                            'GRUPO LA MODERNA']);
                            break;
        }
    },[location]);

    return(
        <Row>
            <Col span={24}>
                <TablaHarinera datos={harinera} images={banner_logo} />
            </Col>
            {location.hash === '#trimex' &&
                (
                    <>
                    <Col span={24}>
                        <TablaHarinera datos={productosMarcas('trimex_molinera')} images={[require('../../../assets/images/sitio/6_productos_socios/trimex_molinera/banner-molinera.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/trimex_molinera/logo_molinera.png'),
                            'MOLINERA DE MÉXICO, S.A. DE C.V.']} />
                    </Col>
                    <Col span={24}>
                        <TablaHarinera datos={productosMarcas('trimex_espiga')} images={[require('../../../assets/images/sitio/6_productos_socios/espiga/BANNER_ESPIGA.jpg'),
                            require('../../../assets/images/sitio/6_productos_socios/espiga/logobco_ESPIGA.png'),
                            'Molinera La Espiga']} />
                    </Col>
                    </>
                )
            }
            <Col span={24}>
                <br /><br />
            </Col>
        </Row>
    );
}

export default ProductosSocios;