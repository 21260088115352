import React, {useRef, useState, useEffect} from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import {scroller} from 'react-scroll';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselPublicaciones = (props) => {

    const {publicaciones} = props;
    const {ref_carousel} = useRef(null);
    const [iframe, setIframe] = useState(publicaciones[0]);
    const [lugar, setLugar]=useState('revista');

    console.log('¡las publivaciones:');
    console.log(publicaciones);

    const settings = {
        
        dots: false,
        speed: 500,
        slidesToShow: lugar === 'revista' ? 4 : 5,
        slidesToScroll: 1
      };

      const location = useLocation();
    

      useEffect(() => {
        if(location.hash === '#revista'){
            scroller.scrollTo('header');
            setLugar('revista');
            console.log(location);
            console.log(location.search.split(';')[0].substr(location.search.split(';')[0].length-2));
            switch(location.search.split(';')[0].substr(location.search.split(';')[0].length-2)){
                case '00': setIframe(publicaciones[0]); break;
                case '01': setIframe(publicaciones[1]); break;
                case '02': setIframe(publicaciones[2]); break;
                case '03': setIframe(publicaciones[3]); break;
                
                default: setIframe(publicaciones[0]); break;
            }
            
        }else if(location.hash === '#reportes'){
            setLugar('reporte');
            switch(location.search.split(';')[0].substr(location.search.split(';')[0].length-2)){
                case '00': setIframe(publicaciones[0]); break;
                case '01': setIframe(publicaciones[1]); break;
                case '02': setIframe(publicaciones[2]); break;
                case '03': setIframe(publicaciones[3]); break;
                case '04': setIframe(publicaciones[4]); break;
                default: setIframe(publicaciones[0]); break;
            }
        }
    },[location, publicaciones]);


    let visualizar_publicaciones = publicaciones.map((publicacion, index) => {
        console.log("index");
        console.log(index);
        return(
            <div onClick={() => {setIframe(publicaciones[index])}}>
                
                <img src={publicacion.img_url} alt="" className="img_carousel_r" />
            </div>
        );

    });

    return(
        <Row>
            <Col xs={{span:22, offset:1}} md={{span:16, offset:4}}>
                <iframe src={iframe.iframe_url} className="img_principal_publicacion" />
                <br /><br />
            </Col>
            <Col xs={{span:22, offset:1}} md={{span:12, offset:6}}>
            <Slider ref={ref_carousel} {...settings}>
                { visualizar_publicaciones }
            </Slider>
            </Col>
        </Row>
    );
    

}

export default CarouselPublicaciones;