import React from 'react';
import { Row, Col } from 'antd';
import { Element } from 'react-scroll';

const datos_tabla = [
    {cargo:'Presidente', empresa:'Harinera Anáhuac, S.A. de C.V. '},
{cargo:'1er Vicepresidente', empresa:'Grupo TRIMEX S.A. de C.V. '},
{cargo:'2do Vicepresidente', empresa:'Molino Harinero San Blas S.A. de C.V. '},
{cargo:'3er Vicepresidente', empresa:'Grupo KASTO Molinos, S.A. de C.V. '},
{cargo:'Vicepresidente ', empresa:'Harinera Tlalnepantla, S.A. de C.V. '},
{cargo:'Vicepresidente ', empresa:'Harinera de Chiapas, S.A. de C.V '},
{cargo:'Vicepresidente ', empresa:'Pastas Molisaba, S.A de C.V '},
{cargo:'Tesorero', empresa:'Fábrica de Harinas Elizondo, S.A. de C.V. '},
{cargo:'Presidente Ejecutivo', empresa:'Mtro. Felipe de Javier Peña Dueñas'},
{cargo:'Consejero ', empresa:'Harinera El Paraíso, S.A. de C.V. '},
{cargo:'Consejero ', empresa:'Harinera de Texcoco, S.A. de C.V. '},
{cargo:'Consejero ', empresa:'Molinos del Sudeste, S.A. de C.V. '},
{cargo:'Consejero ', empresa:'Molino de Trigo Guadalupe, S.A. de C.V. '},
{cargo:'Consejero', empresa:'Harinera de Atotonilco, S.A. de C.V. '},
{cargo:'Consejero ', empresa:'SPR Hernando de Villafañe, S.P.R de R.L'},

];

const TablaConsejo = () => {

    const datos_tabla_visualizar = datos_tabla.map(datos => {
        return(
            <Row>
                <Col xs={{span:12, offset:0}} md={{span:10, offset:0}} className="cargo_consejo_conocenos">{datos.cargo}</Col>
                <Col xs={{span:12, offset:0}} md={{span:10, offset:4}} className="empresa_consejo_conocenos">{datos.empresa}</Col>
            </Row>
        );
    });

    return(
        <>
        <Row className="back_amarillo">
            <Col span={20} offset={2}>
            <Element name="#consejo"></Element>
                <p className="titulo_consejo_conocenos" id="consejo">
                    CONSEJO DIRECTIVO
                </p>
            </Col>
        </Row>
        <Row className="contenedor_tabla_consejo_conocenos">
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
                <br />
                    {datos_tabla_visualizar}
                <br />
            </Col>
        </Row>
        </>
    );

}

export default TablaConsejo;
