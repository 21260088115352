import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import '../../../../assets/css/notasGeneral.css';

import encabezado from '../../../../assets/images/sitio/10_BOLG/amarillas/blog_beneficios_agricultura_canimolt.png';

export const BeneficiosAgricultura = () => {
  return (
    <>
    <Helmet>
        <meta name="description" content="" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="canimolt.org/los-beneficios-de-la-agricultura-de-conservacion-de-la-voz-de-un-productor-de-trigo" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Francisco Alarcón"/>
        <meta name="author" content="Francisco Alarcón" />
        <meta property="og:title" content="LOS BENEFICIOS DE LA AGRICULTURA DE CONSERVACIÓN DE LA VOZ DE UN PRODUCTOR DE TRIGO" />
        <meta property="og:description" content="Ante los altos costos de los fertilizantes, la variabilidad climática y los elevados costos de producción en general, la agricultura de conservación se perfila como una alternativa para mantener la rentabilidad en la agricultura, asegura este productor." />
        <meta property="og:url" content="canimolt.org/los-beneficios-de-la-agricultura-de-conservacion-de-la-voz-de-un-productor-de-trigo" />
        <meta property="og:site_name" content="CANIMOLT" />
        <meta property="og:image" content={encabezado} />
        <meta property="og:image:secure_url" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:title" content="LOS BENEFICIOS DE LA AGRICULTURA DE CONSERVACIÓN DE LA VOZ DE UN PRODUCTOR DE TRIGO" />
        <meta name="twitter:image" content={encabezado} />
      </Helmet>
      <Row>
      <Col span={24} className="banner_seccion_blog">
        <p className="titulo_blog_banner">¡BIENVENIDO!</p>
        <p className="texto_blog_banner">¡Conozca más sobre el trigo, el cual ha alimentado a la humanidad por más de 4 mil años!</p>
      </Col>
      <Col span={20} offset={2}>
        <p className="nuestro_blog">Nuestro Blog</p>
      </Col>
      <Col xs={{span:18, offset:3}} md={{span:12, offset:2}} >
        <img src={encabezado} alt="" className="imge_encabezado_nota_blog" />
      </Col>
      <Col xs={{span:18, offset:3}} md={{span:12, offset:2}} className="contenedor_nota_texto">
        <p className="titulo_nota">LOS BENEFICIOS DE LA AGRICULTURA DE CONSERVACIÓN DE LA VOZ DE UN PRODUCTOR DE TRIGO</p>
        <p className="nota_texto justificar">
        <i>Ante los altos costos de los fertilizantes, la variabilidad climática y los elevados costos de producción en general, la agricultura de conservación se perfila como una alternativa para mantener la rentabilidad en la agricultura, asegura este productor. </i>
        <br />
        By Francisco Alarcón
        <br /><br />
        Alfonso Romo es un productor de trigo y cártamo en el Valle del Mayo, una de las zonas agrícolas más representativas del estado de Sonora, en México. Él es parte de la segunda generación de una familia de agricultores y, de los 34 años que lleva siendo productor de tiempo completo, hace alrededor de doce que incursionó en la práctica de la agricultura de conservación. 
        <br /><br />
El productor comenzó a practicar ese sistema de producción “buscando cómo bajar los costos sin perder las producciones que ya se tenían. Y así nos empezamos a involucrar. Poco a poco hemos ido aumentando el área sembrada con agricultura de conservación. Hemos tenido dificultades en el camino para llegar a producir lo mismo que con la agricultura convencional, pero hemos visto los beneficios y buscamos incrementar la superficie con este sistema sustentable”, comenta. 
<br /><br />
La agricultura convencional en la región implica mucho movimiento del suelo y la aplicación de altas dosis de fertilizantes, pero a través de la adopción de prácticas sustentables de la mano del Centro Internacional de Mejoramiento de Maíz y Trigo (CIMMYT), Alfonso comenta que se ha dado cuenta que “no necesitamos tirar todo el fertilizante como se hace normalmente en la agricultura convencional. Podemos bajar las dosis, bien respaldados con un análisis de suelo, con el uso de sensores ópticos y drones”. 
<br /><br />
“Hemos aprendido mucho y este año logramos producir lo mismo que estamos produciendo con agricultura convencional, pero con menos cantidad de fertilizante — hasta 100 unidades de nitrógeno menos—. Con los costos de los fertilizantes como están, ha sido un gran ahorro: en los lotes donde aplicamos todas las prácticas sustentables tuvimos ahorros de fertilizantes desde un 30 hasta un 40%”, puntualiza Alfonso. 
<br /><br />
Para el productor, los sensores ópticos han sido una pieza clave para optimizar la aplicación de fertilizantes: “Así como nos dio las lecturas se fue aplicando el fertilizante y tuvimos muy buenos resultados. No sobrefertilizamos y tuvimos producciones excelentes. Las sobrefertilizaciones van y terminan en el mar. Y en estos momentos además tiene mucho que ver con la economía, con el costo de los fertilizantes”. 
<br /><br />
El ahorro en fertilizantes no es el único: “En cuestión de trabajos, como en las 50 hectáreas donde hemos implementado agricultura de conservación ya no se mueve el suelo, hemos tenido más ahorros, un ahorro del costo total de producción de entre 10 y 14%”.
<br /><br />
Dejar de mover el suelo y cubrirlo con residuos de la cosecha anterior ha tenido beneficios adicionales para el productor, quien manifiesta que otro de los problemas que tenía en sus lotes era la poca materia orgánica; sin embargo, “con el rastrojo dejas protegida la tierra todo el verano y las malezas no salen tan fácil. Y al momento de las siguientes siembras uno va a tener mayor captación de agua y mayor duración de la humedad. Esto con los años ayuda a subir la materia orgánica y las producciones”, comenta. 
<br /><br />
Por los diversos beneficios observados, el productor continúa implementando prácticas sustentables. Actualmente está rotando cultivos y manifiesta que ha tenido buenas experiencias con el cultivo del ajonjolí y con diversas prácticas que ha aprendido para optimizar el riego. Por esta razón, menciona, desea ir aumentando la superficie cultivada con agricultura de conservación.
<br /><br />
“Hemos logrado cosas muy buenas, hemos batallado en otras, pero ahí vamos. Mi invitación es que los productores se acerquen con la gente que sabe de esto, que se asesoren un poco y aprendan, ir aprendiendo poco a poco y van a ver los beneficios de la agricultura de conservación, no se van a arrepentir”. 
<br /><br />
Finalmente, Alfonso comenta que más allá del beneficio económico, la adopción de prácticas de agricultura sustentable responde a los grandes desafíos de la humanidad: “Un reto que tenemos es el clima. Cómo nos ha estado cambiando, la escasez de lluvias, los fríos intensos en ocasiones y la falta de frío en otros momentos exige también que cambiemos nuestros sistemas de producción”.  
        </p>
        <br /><br /><br />
      </Col>
    </Row>
    </>
  );
}

export default BeneficiosAgricultura;