import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import '../../../assets/css/home.css';

import blog_plato from '../../../assets/images/sitio/1_HOME/nuestroblog_platodelbiencomer.png';
import blog_trigo from '../../../assets/images/sitio/1_HOME/nuestroblog_consumodetrigopercapita.png';
import blog_granos from '../../../assets/images/sitio/publicaciones/blog_canimolt_16nov.png';

const Blog = () => {
  const [ver_siguiente, verSiguiente] = useState(false);
  const [ver_anterior, verAnterior] = useState(true);

  const publi = [
    {img:'https://via.placeholder.com/160x210', dia:'16',fecha:'noviembre, 2021',titulo:'La importancia de consumir granos enteros',url:'',prev:'El 19 de noviembre se celebra el Día Internacional del Grano Entero. La ingesta de cereales integrales no solo aporta diversos beneficios a la nutrición y salud humana, también representa una contribución a la producción sostenible de alimentos.'},
    {img:'https://via.placeholder.com/160x210', dia:'03',fecha:'marzo, 2020',titulo:'Los derivados: Un arma de doble filo',url:'',prev:'A partir de 1973, el CBOE (filial del CBOT) desarrolló el Contrato de opciones, un novedoso producto que tuvo gran éxito en el mercado de acciones de papel cuyas características ya habían sido estandarizadas.'},
    {img:'https://via.placeholder.com/160x210', dia:'24',fecha:'marzo, 2020',titulo:'Siembra de trigo en surco con pileteo',url:'',prev:'El seguimiento genético ha tenido fuerte influencia en el aumento de la productividad de trigo en México mediante la generación de variedades que han contribuido a incrementar el mejoramiento del rendimiento nacional.'},
  ];

  /*let ver_publicaciones = publi.map((ver)=>{
    return(
      <Col span={11} offset={1} className="contenedor_div_publi">
        <div className="div_publicacion">
          <img alt="" className="img_div_publi" src={ver.img} />
          <p className="titu_div_publi">{ver.titulo}</p>
          <p className="desc_div_publi">{ver.prev}</p>
          <div class="fecha_div">
            <span className="dia_div">{ver.dia}</span><br />
            <span className="mes_div">{ver.fecha}</span>
          </div>
        </div>
      </Col>
    );
  });*/
  return (
    <>
    <Row className="contenedor_blog_home">
      <Col span={20} offset={2}>
        <Row align="middle">
          <Col xs={{span:20, offset:2}} lg={{span:10, offset:0}}>
            <p className="titulo_blog_home">NUESTRO BLOG</p>
            <span className={ver_siguiente === true ? 'ocultar_blog' : 'ver_blog'}>
            <p className="sub_blog_home" >La importancia de consumir granos enteros</p>
            <p className="text_blog_home">El 19 de noviembre se celebra el Día Internacional del Grano Entero. La ingesta de cereales integrales no solo aporta diversos beneficios a la nutrición y salud humana, también representa una contribución a la producción sostenible de alimentos. </p>
            <p className="contenedor_boton_home_blog">
              <Link class="blog_mas_blanco" to="/la-importancia-de-consumir-granos-enteros">LEER MÁS</Link>
              <Link class="blog_mas_negro" onClick={() => {verSiguiente(true); verAnterior(false)}}>SIGUIENTE</Link>
            </p>
  </span>
            <span className={ver_anterior === true ? 'ocultar_blog' : 'ver_blog'}>
            <p className="sub_blog_home" >EL PLATO DEL BIEN COMER</p>
            <p className="text_blog_home">Fue diseñado para los mexicanos de acuerdo con nuestras características y necesidades. Es una guía para lograr una alimentación correcta. Nos ayuda a prevenir la obesidad, la hipertensión, ciertos tipos de cáncer y enfermedades del corazón. A todos nos hace sentir bien, vernos bien y tener energía para las actividades que realizamos cada día. </p>
            <p className="contenedor_boton_home_blog">
              <Link class="blog_mas_blanco" to="/plato-del-bien-comer">LEER MÁS</Link>
              <Link class="blog_mas_negro" onClick={() => {verAnterior(true); verSiguiente(false)}}>ANTERIOR</Link>
            </p>
            </span>
          </Col>
          <Col xs={{span:20, offset:2}} lg={{span:12, offset:1}}>
            <img alt=""  className={ver_siguiente === true ? 'ocultar_blog' : 'ver_blog'} src={blog_granos} style={{width:'100%'}}/>
            <img alt="" className={ver_anterior === true ? 'ocultar_blog' : 'ver_blog'} src={blog_plato} style={{width:'100%'}}/>
          </Col>
        </Row>
      </Col>
    </Row>
    </>
  );
}

export default Blog;
