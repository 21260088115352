import React from 'react';
import { Row, Col } from 'antd';

const TablaHarinera = (props) => {

    const {images, datos} = props;

    console.log(datos);

    const styles = {
        background:`url(${images[0]})`
    }

    

    const visulizar_datos = datos.map(registro => {

        const presentaciones = registro.presentaciones.split(' ').map(presentacion => {
            return(
                <>
                    {presentacion}<br />
                </>
            );
        });
        return(
            <Row className="contenedor_producto">
                <Col xs={{span:2, offset:0}} md={{span:2, offset:0}}>
                    <img src={registro.img_src} className="img_marca_productos" alt={registro.marca} />
                </Col>
                <Col xs={{span:2, offset:0}} md={{span:3, offset:0}}>
                    <p className="marca_productos color_amarillo">{registro.marca}</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                    <p className="tipo_harina_productos">{registro.tipo_harina}</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                    <p className="usos_productos">{registro.usos}</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                    <p className="presentacion_productos">{presentaciones}</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                    <p className="comentarios_productos">{registro.comentarios}</p>
                </Col>
                <Col xs={{span:3, offset:0}} md={{span:3, offset:0}}>
                    <a href={registro.url} target="_blank"><p className="url_productos">{registro.url}</p></a>
                </Col>
            </Row>
        );
    });

    const visulizar_datos_movil = datos.map(registro => {

        const presentaciones = registro.presentaciones.split(' ').map(presentacion => {
            return(
                <>
                    {presentacion}<br />
                </>
            );
        });
        return(
            <Row className="contenedor_producto">
                <Col span={11}>
                <p className="texto_header_tabla color_gris">MARCA</p>
                <a href={registro.url} target="_blank"><p className="marca_productos color_amarillo">{registro.marca}</p></a>
                <a href={registro.url} target="_blank"><img src={registro.img_src} className="img_marca_productos" alt={registro.marca} /></a>
                </Col>
                <Col span={11}>
                    <p className="texto_header_tabla color_gris">TIPO DE HARINA</p>
                    <p className="tipo_harina_productos">{registro.tipo_harina}</p>
                    <p className="texto_header_tabla color_gris">USOS</p>
                    <p className="usos_productos">{registro.usos}</p>
                    <p className="texto_header_tabla color_gris">PRESENTACIONES</p>
                    <p className="presentacion_productos">{presentaciones}</p>
                </Col>
            </Row>
        );
    });


    return(
        <>
        <Row className="banner_principal_productos" style={styles}>
                <Col span={24} style={{textAlign:'center'}}>
                    <img alt="" src={images[1]} className="logo_marca" />
                    <br/>
                    <p className="nombre_marca color_amarillo">
                        {images[2]}
                    </p>
                </Col>
            </Row>
            <Col xs={{span:0, offset:0}} md={{span:22, offset:1}}>

            <Row>
                <Col xs={{span:2, offset:0}} md={{span:2, offset:0}}>
                    <p className="texto_header_tabla color_gris">LOGO</p>
                </Col>
                <Col xs={{span:2, offset:0}} md={{span:3, offset:0}}>
                <p className="texto_header_tabla color_gris">MARCA DE HARINA</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                <p className="texto_header_tabla color_gris">TIPO HARINA</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                <p className="texto_header_tabla color_gris">USOS</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                <p className="texto_header_tabla color_gris">PRESENTACIÓN</p>
                </Col>
                <Col xs={{span:4, offset:0}} md={{span:4, offset:0}}>
                <p className="texto_header_tabla color_gris">COMENTARIOS</p>
                </Col>
                <Col xs={{span:3, offset:0}} md={{span:3, offset:0}}>
                <p className="texto_header_tabla color_gris">WEB</p>
                </Col>
            </Row>

            {visulizar_datos}
            </Col>
            

            <Col xs={{span:22, offset:1}} md={{span:0, offset:0}}>

            

            {visulizar_datos_movil}
            </Col>
        </>
    );
}

export default TablaHarinera;
