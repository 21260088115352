import React from 'react';
import { Row, Col } from 'antd';
import { Element } from 'react-scroll';

import icono_cereal from '../../../assets/images/sitio/3_cereales/icono_seccioncereales.png';
import icono_tazon_cereal from '../../../assets/images/sitio/3_cereales/avena_cereales.png';
import plato_alimenticio from '../../../assets/images/sitio/3_cereales/Circulo-Alimenticio.gif';
import texto_alimenticio from '../../../assets/images/sitio/3_cereales/titulo_criculo nuitmental.png';

import '../../../assets/css/cereales.css';
import TablaPropiedades from './TablaPropiedades';

const Cereales = () => {

    return(
        <>
        <Row className="banner_principal_cereales">
             <Col XS={{span:24, offset:8}} md={{span:8, offset:8}} span={24} >
                    <p className="contenedor_icono_principal_cereales">
                        <img alt="" src={icono_cereal} className="img_icono_principal_cereales" />
                        <br />
                        <span class="palabras_principal_cereales">Cereales</span>
                    </p>
             </Col>
        </Row>
        <br />
        <Row className="back_amarillo" id="que-son">
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
            <Element name="#que-son"></Element>
                <p className="texto_titulo_cereales">
                    ¿QUÉ SON LOS CEREALES?
                </p>    
            </Col>
        </Row>
        <Row>
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
                <p className="texto_info_cereales color_gris">
                Históricamente, los cereales son los alimentos más importantes en la dieta humana y animal, debido a
                    sus altas cualidades nutrimentales, ya que contienen hidratos de carbono, proteínas, sales minerales y
                    vitaminas, nutrientes indispensables para el ser humano; además de bastante fibra. A diferencia de otros
                    alimentos, los cereales pertenecen a un grupo de plantas de la familia de las gramíneas, presentes en
                    prácticamente casi todos los países del mundo y se han constituido, desde siempre, en la base de la
                    alimentación de sus pobladores.
                    <img alt="" src={icono_tazon_cereal} className="img_tazon_cereales no_ver_tazon" />
                    <br /><br />


                    El cultivo de cualquier cereal es relativamente
                    sencillo y de bajo costo, por ello todas las
                    civilizaciones que han habitado el planeta lo
                    han tomado como fuente de vitaminas,
                    minerales, proteínas, entre otros nutrientes.
                    
                    <br /><br />
                    Se caracterizan porque la semilla y el fruto son una misma cosa. Están compuestos por la cascarilla que
                    está integrada por bras de celulosa que contiene vitamina B1, el germen que contiene grasas
                    insaturadas, y la almendra interna que se compone de almidón.
                    <br /><br />
                    Los principales cereales que se consumen son el trigo, el arroz, el maíz, el centeno, la cebada, la avena y
                    el mijo.
                    <br /><br />
                    El desarrollo en la siembra de estos alimentos, se ha dado dependiendo de la ubicación, el clima, los tipos
                    de tierra y los hábitos de consumo de los pobladores, que están estrechamente relacionada con su
                    cultura. Así, el trigo se consume principalmente en los países de Europa y el Norte de América, el maíz en
                    Norte, Centro y Sudamérica, el arroz en Asia, y en África además del mijo, también se consume trigo y
                    ahora maíz. En el caso de avena y centeno su consumo es menor y su localización es más bien hacia los
                    países del norte del planeta.
                    
                </p>
                <img alt="" src={icono_tazon_cereal} className="img_tazon_cereales ver_tazon" />
            </Col>
        </Row>
        <br /><br />
        <Row className="back_amarillo" id="propiedades">
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
            <Element name="#propiedades"></Element>
                <p className="texto_titulo_cereales">
                    PROPIEDADES ALIMENTICIAS
                </p>
            </Col>
        </Row>
        <br />
        <TablaPropiedades />
        <br /><br />
        <Row className="back_amarillo" id="nutricion">
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
            <Element name="#nutricion"></Element>
                <p className="texto_titulo_cereales">
                    NUTRICIÓN
                </p>
            </Col>
        </Row>
        <Row align="bottom">
            <Col xs={{span:22, offset:1}} md={{span:10, offset:2}}>
                <img alt="" src={texto_alimenticio} className="img_texto_alimenticio" />
            </Col>
            <Col xs={{span:22, offset:1}} md={{span:10, offset:0}}>
                <img alt="" src={plato_alimenticio} className="img_plato_alimenticio" />
            </Col>
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
                <p className="texto_alimenticio color_gris">
                Considerando la opinión de expertos nutriólogos mexicanos vinculados al Instituto Nacional de Nutrición Salvador Zubirán (INN), 
                organismo de alto prestigio internacional, se ha propuesto el Circulo o Plato Nutrimental, que se realizó considerando la dieta 
                que satisface al consumidor nacional.
                </p>
            </Col>
        </Row>
        <br /><br />
        </>
    );
}

export default Cereales;
