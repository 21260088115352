import React from 'react';
import { Row, Col } from 'antd';
import { Element } from 'react-scroll';

import '../../../assets/css/harina.css';

import seccion_harina from '../../../assets/images/sitio/5_HARINA/icono_seccionharina.png';
import img_definicion from '../../../assets/images/sitio/5_HARINA/imagen_harinadetrigo.png';
import img_clacsificacion from '../../../assets/images/sitio/5_HARINA/creales_harinadetrigo.png';
import img_pan from '../../../assets/images/sitio/5_HARINA/cereales_pan.png';
import gif_vitaminas from '../../../assets/images/sitio/5_HARINA/VITAMINAS.gif';

const Harina = () => {
  return (
    <>
      <Row>
        <Col span={24} className="banner_harina">
          <img alt="" src={seccion_harina} className="icono_harina" />
          <p className="text_banner_harina">
            Harina
          </p>
        </Col>
        <Col span={24} className="contenedor_cintillo">
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
              <Element name="#definicion"></Element>
              <p className="cintillo_harina">DEFINICIÓN DE LA HARINA DE TRIGO</p>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
          <p className="text_definicion_harina">La harina es el polvo que se obtiene de la molienda del grano de trigo maduro, entero o quebrado, limpio, sano y seco, en el que se elimina gran parte de la cascarilla (salvado) y el germen.
            El resto se tritura hasta obtener un grano de finura adecuada.</p>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }}>
          <p className="text_definicion_harina">La harina contiene entre un 65 y un 70% de almidones, pero su valor nutritivo fundamental está en su contenido, ya que tiene del 9 al 14% de proteínas; siendo las más importantes la gliadina y la gluteína, además de contener otros componentes como celulosa, grasas y azúcar.<br /><br />
            La molienda de trigo consiste en separar el endospermo que contiene el almidón de las otras partes del grano. El trigo entero rinde más del 72% de harina blanca y el resto es un subproducto. En la molienda, el grano de trigo se somete a diversos tratamientos antes de convertirlo en harina.</p>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }}>
          <img alt="" src={img_definicion} className="animacion_img" />
        </Col>
        <Col span={24}>
        <Row>
                <Col span={24} className="banner_historia_harina" >
                    <Element name="#historia"></Element>
                    <p className="texto_banner_historia" >HISTORIA DE LA MOLIENDA</p>
                </Col>
                <Col span={20} offset={2} className="especio_contenedor">
                    <p className="text_definicion_harina">
                        Para el hombre primitivo, el trigo comenzó a ser indispensable por su fácil cultivo y por la dificultad de obtener carne, así que comenzó a ingerirlo. Como el trigo es de granos duros y casi imposible triturarlo con los dientes, el hombre tuvo que ingeniárselas para molerlo, así que con la ayuda de dos piedras comenzó esta labor de macerar el grano, conservando íntegramente sus cualidades nutritivas. El resultado de esta molienda fue un polvo integral, que mezclado con agua y en presencia de levaduras naturales, le daba un aroma agradable y hacían que duplicara su volumen al descomponerse, por lo que posteriormente con el uso de fuego se fue cociendo, dándole un grato sabor. Esto evolucionó de tal manera que desde hace miles de años, la fabricación del pan es una de las principales industrias de alimentos de la humanidad.
                        <br /><br />
                        Para facilitar y mejorar la trituración del grano, el hombre inventó el molino, que facilitó la molienda y substituyó al par de piedras de antaño. Primero se utilizó un sistema complicado, pues sobre un gran bloque de piedra fijo y plano se movía otra piedra igualmente redonda de gran peso y estriada. Esta última debía girar sobre un eje y era movida por animales, o personas esclavas o prisioneras.
                        <br /><br />
                        Años antes de Cristo, se inventó la rueda y con ello se identificó que si se le hacían “paletas” podía ser movida por el agua de ríos que, si se le hacia una “herida de agua” es decir, un canal, aumentaba la presión y movía con mayor facilidad estas piedras o ruecas, gracias a un engranaje diseñado para tal fin.
                        <br /><br />
                        Para el siglo VIII, los árabes inventaron el molino de viento, aprovechando el conocimiento de la mecánica de el de agua, lo que facilitó aún más la molienda y trasladó los molinos a las zonas productoras de trigo y fuera de los Feudos; además de ello, perfeccionaron las piedras planas, las cuales fueron substituidas por cónicas. Éstas estaban estriadas de cierta manera los que facilitaba el movimiento del trigo en trituración desde el centro hasta la periferia de la piedra.
                        <br /><br />
                        En el siglo pasado se perfeccionó el sistema de molienda y se comenzó a hacer con rodillos cilíndricos y la energía para mover fue eléctrica. Con el correr del tiempo, se comenzó la molienda con la limpieza previa del grano sometiéndolo a la acción del viento y del trabajo manual con cribas. Se le quitaba paja, residuos, guijarros, arena y diversos tipos de semillas ajenas al trigo.
                        <br /><br />
                        Posteriormente, con la intención de obtener harinas más blancas y panes y pasteles con otra consistencia, se empezó a limpiar el trigo pasándolo por un cilindro revestido de esmeril; luego siguió el proceso del templado para ajustar la humedad que facilitara la separación de la cascarilla. Eso dio origen a la industrialización del proceso de elaboración de harina blanca, ya que la misma se convirtió en la base dietética de todo ser humano, y se necesitaba un volumen mayor y una materia prima de más calidad.

                    </p>
                </Col>
            </Row>
            <br /><br />
        </Col>
        <Col span={24} className="contenedor_cintillo">
          <Row>
            <Element name="#clasificacion"></Element>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
              <p className="cintillo_harina">CLASIFICACIÓN Y USO</p>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }}>
          <img alt="" src={img_clacsificacion} className="animacion_img2" />
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }}>
          <p className="text_definicion_harina">
            <strong>Para clasificar las harinas se utilizan los siguientes valores:</strong><br /><br />
            <strong>W.</strong> Es la fuerza que tiene la harina.<br /><br />
            <strong>P/L.</strong> Índica el equilibrio de la harina y ayuda a saber que tipo de trabajo panadero es más adecuado para cada harina.<br /><br />
            <strong>Valor P. (Tenacidad).</strong> Es la absorción que tiene la harina sobre el agua.<br /><br />
            <strong>Valor L. (Extensibilidad).</strong> Es la capacidad que tiene la harina para ser estirada cuando se mezcla con agua.<br /><br />
            <strong>La absorción</strong> es un dato de mucha importancia en panificación y depende de la calidad del gluten.<br /><br />
            <strong>Falling Number.</strong> Es para medir indirectamente la actividad alfa-amilásica existente en la harina.<br /><br />
            <strong>Maltosa.</strong> Es el azúcar existente en la harina sobre el que actúa la levadura para producir gas carbónico durante el proceso de fermentación.<br /><br />
            Por consiguiente es necesario relacionar todos los valores y no limitarse a uno solo, ya que puede darse el caso de que dos harinas tengan el mismo W pero diferente P/L, y por lo tanto su comportamiento en panificación será muy distinto.</p>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 9, offset: 2 }}>
          <p className="titu_tablas_harina table_animacion" >HARINA EXTRAFINA</p>
          <table class="table_animacion">
            <tr className="cabecera_tabla_harina">
              <td className="cabecera_pading">CARACTERÍSTICAS</td>
              <td className="cabecera_pading">USOS</td>
            </tr>
            <tr>
              <td className="tab_harina_caracteristicas">
                W=270-330<br />
                P/L=0,9-1,3<br />
                P=100-130<br />
                L=90-120<br />
                Gluten seco= 9-12%<br />
                Falling Number= 320-380 seg. Índice de Maltosa=2-2,4</td>
              <td className="tab_harina_uso">
                Panes muy ricos y bollería especial.
              </td>
            </tr>
          </table>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 9, offset: 2 }}>
          <p className="titu_tablas_harina table_animacion">HARINA FINA</p>
          <table class="table_animacion">
            <tr className="cabecera_tabla_harina">
              <td className="cabecera_pading">CARACTERÍSTICAS</td>
              <td className="cabecera_pading">USOS</td>
            </tr>
            <tr>
              <td className="tab_harina_caracteristicas">
                W=180-270<br />
                P/L=0,5-0,7<br />
                P=50-90<br />
                L=100-120<br />
                Gluten seco= 0,9-11.5% Falling Number= 320-380 seg. Índice de Maltosa=1,8-2,2
              </td>
              <td className="tab_harina_uso">
                Para panes especiales.<br />
                Fermentación larga y proceso frío, de bollería y panadería.
              </td>
            </tr>
          </table>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 9, offset: 2 }}>
          <p className="titu_tablas_harina table_animacion">HARINA SEMIFINA</p>
          <table class="table_animacion">
            <tr className="cabecera_tabla_harina">
              <td className="cabecera_pading">CARACTERÍSTICAS</td>
              <td className="cabecera_pading">USOS</td>
            </tr>
            <tr>
              <td className="tab_harina_caracteristicas">
                W=110-180<br />
                P/L=0,4-0,6<br />
                P=40-65<br />
                L=100-120<br />
                Gluten seco= 8-11%<br />
                Falling Number= 27-330 seg. Índice de Maltosa=1,8-2,2
              </td>
              <td className="tab_harina_uso">
                Para procesos medios y largos de fermentación.<br />
                Croissant, hojaldres y bizcochos.
              </td>
            </tr>
          </table>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 9, offset: 2 }}>
          <p className="titu_tablas_harina table_animacion">HARINAS SUAVES (GALLETERAS)</p>
          <table class="table_animacion">
            <tr className="cabecera_tabla_harina">
              <td className="cabecera_pading">CARACTERÍSTICAS</td>
              <td className="cabecera_pading">USOS</td>
            </tr>
            <tr>
              <td className="tab_harina_caracteristicas">
                W=80-110<br />
                P/L=0,2-0,3<br />
                P=30-40<br />
                L=60-75<br />
                Gluten seco= 7-9%<br />
                Falling Number= 250-300 seg. Índice de Maltosa=1,6-1,8
              </td>
              <td className="tab_harina_uso">
                Para panificaciones muy rápidas y muy mecanizadas. Con una fermentación máxima de 90 minutos.<br />
                También se pueden usar para magdalenas y otras elaboraciones abiscochadas.
              </td>
            </tr>
          </table>
        </Col>
        <Col span={24} className="contenedor_cintillo">
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
              <Element name="#cualidades"></Element>
              <p className="cintillo_harina">CUALIDADES NUTRIMENTALES</p>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }}>
          <p className="text_definicion_harina">Casi la totalidad de las harinas de trigo mexicanas están fortificadas con vitaminas como la niacina (B3), riboflavina (B2), tiamina (B1), ácido fólico, hierro y zinc.<br /><br />
            El Gobierno Federal y los industriales molineros de trigo mexicanos, pretenden incrementar el combate contra la desnutrición y la anemia que son los padecimientos más graves del país, además de combatir otras enfermedades específicas como la anencefalia, columna bífida que afecta a los bebes durante el proceso de gestación o embarazo, o incluso pretende atender problemas de crecimiento.<br /><br />
            Con la adición o fortificación de las harinas, fundamentalmente las mujeres y los niños al consumir pan, galletas, sopas de pasta y tortillas de harina de trigo, mejorarán su alimentación, ya que contienen:<br /><br />
            <strong>Vitamina B1 (tiamina),</strong> necesaria para el funcionamiento del sistema nervioso y ayuda a liberar la energía de los carbohidratos.<br /><br />
            <strong>Vitamina B2 (riboflavina),</strong> ayuda a formar glóbulos rojos y material genético.<br /><br />
            <strong>Vitamina B3 niacina,</strong> necesaria para el funcionamiento del sistema nervioso y digestivo.<br /><br />
            <strong>Hierro,</strong> que permite eliminar o disminuir problemas de anemia ferropriva.<br /><br />
            <strong>Zinc,</strong> el cual contribuye al mejor desarrollo o crecimiento en los niños, es necesario para el metabolismo y la digestión. Ayuda a sanar heridas y a reparar los tejidos.<br /><br />
            <strong>Ácido fólico o folacina,</strong> que permite disminuir problemas de anencefalia o del tubo neural (columna bífida), en el periodo de gestación, por lo que se recomienda la ingesta por parte de las madres embarazadas.<br /><br />
            Por lo anterior, la Industria Molinera de Trigo de México, recomienda mejorar la dieta alimenticia consumiendo los nuevos y nutritivos panes, galletas, sopas de pastas y tortillas, hechas con harina de trigo mexicana.
          </p>
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 0 }}>
          <img alt="" src={img_pan} className="animacion_img" /><br />
          <img alt="" src={gif_vitaminas} className="animacion_img_gift" />
        </Col>
      </Row>
    </>
  );
}

export default Harina;